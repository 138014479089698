<template>
    <div class="organizations__container">
<!--        <zero-state-->
<!--          v-if="showZeroState"-->
<!--          :meta="zeroStateMeta"-->
<!--          :background-transparent="false"-->
<!--          @open-create-role-modal="goToRolePath"-->
<!--          @open-add-user-modal="openModal({ name: 'InviteUserModal' })"-->
<!--        />-->
        <Container class="organizations__body-container">
            <TopBar/>
            <new-spinner v-if="isLoading || !isInit"/>
            <div v-else>
                <Organization v-if="currentTabSelect === 0"/>
                <OrganizationUsers v-else-if="currentTabSelect === 1"/>
                <OrganizationRoles v-else-if="currentTabSelect === 2 && !detailRole"/>
                <OrganizationListRole v-else/>
            </div>
        </Container>
        <RemoveRoleModal/>
        <RemoveUserModal/>
        <InviteUserModal/>
        <UpdateUserModal/>
        <TransferOrganizationModal />
        <TwoFaRestriction/>
        <TwoFaConfirmation :modal-name="'TransferOrganizationModal'" />
    </div>
</template>

<script>
import TopBar from "@components/elements/organizations/TopBar";
import Organization from "@components/elements/organizations/Organization";
import OrganizationUsers from "@components/elements/organizations/OrganizationUsers";
import OrganizationRoles from "@components/elements/organizations/OrganizationRoles";
import OrganizationListRole from "@components/elements/organizations/OrganizationListRole";
import Preloader from "/public/components/common/uiKit/spinner/index.vue";
import RemoveRoleModal from "/public/components/modals/organization/RemoveRoleModal";
import InviteUserModal from "/public/components/modals/organization/InviteUserModal";
import UpdateUserModal from "/public/components/modals/organization/UpdateUserModal";
import RemoveUserModal from "/public/components/modals/organization/RemoveUserModal";

import {mapActions, mapGetters, mapState} from "vuex";
import CommonInfoModal from "../components/modals/commonInfo";
import Container from "@/public/components/common/uiKit/Container.vue";
import TransferOrganizationModal from "@/public/components/modals/organization/TransferOrganizationModal.vue";
import {getOrganizationUsers} from "@/public/store/modules/organizations/actions/getOrganizationUsers";
import TwoFaRestriction from "@/public/components/modals/twoFactorAuthorization/TwoFaRestriction.vue";
import TwoFaConfirmation from "@/public/components/modals/twoFactorAuthorization/TwoFaConfirmation.vue";

export default {
    name: "organizations",
    title: 'navigation.organizations',

    components: {
        TwoFaConfirmation,
        TwoFaRestriction,
        TransferOrganizationModal,
        Container,
      CommonInfoModal,
        TopBar,
        Organization,
        OrganizationUsers,
        OrganizationRoles,
        OrganizationListRole,
        Preloader,
        RemoveRoleModal,
        InviteUserModal,
        UpdateUserModal,
        RemoveUserModal,
        NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
        // ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
    },

    created() {
        if (!this.isOrganizationOwner) {
            this.$Message.error(this.$t('rules.not_allowed'));
            this.$router.push('/merchant/dashboard');
            return;
        }
        this.getOrganization();
        this.getOrganizationUsers();
    },
    watch: {
        isOrganizationOwner (state) {
            if (!state) {
                this.$Message.error(this.$t('rules.not_allowed'));
                this.$router.push('/merchant/dashboard');
            }
        },
    },
    computed: {
        ...mapState('app', ['isInit', 'isLoading']),
        ...mapState('organizations', [
            'currentTabSelect',
            'detailRole',
            'organizationName',
            'changeRole',
            'listUsers',
            'listRoles'
        ]),
        ...mapGetters('app', ['isOrganizationOwner']),
        // showZeroState({ organizationName, listUsers, listRoles, detailRole }) {
        //   return organizationName === 'Organization' && !listUsers.length && !listRoles.length && !detailRole
        // },
        // zeroStateMeta() {
        //   const { isActionsAllowedShow } = this
        //
        //   return {
        //     title: this.$t('zero-states.organization.title'),
        //     subtitle: this.$t('zero-states.organization.subtitle'),
        //     notifications: [
        //       this.$t('zero-states.organization.notifications.first-line')
        //     ],
        //     actions: [
        //       {
        //         title: this.$t('zero-states.organization.actions.create-role.title'),
        //         info: this.$t('zero-states.organization.actions.create-role.info'),
        //         btnName: this.$t('zero-states.organization.actions.create-role.btn-name'),
        //         action: 'open-create-role-modal'
        //       },
        //       {
        //         title: this.$t('zero-states.organization.actions.add-user.title'),
        //         info: this.$t('zero-states.organization.actions.add-user.info'),
        //         btnName: this.$t('zero-states.organization.actions.add-user.btn-name'),
        //         action: 'open-add-user-modal'
        //       }
        //     ],
        //     type: 'default'
        //   }
        // }
    },

    methods: {
        ...mapActions('organizations', [
            'getOrganization',
            'getOrganizationUsers'
        ]),
        ...mapActions('app', ['openModal']),
        goToRolePath() {
          this.$store.commit('organizations/switchTab', 2);
          this.$store.commit('organizations/changeDetailRole', true);
        }
    },
};
</script>

<style lang="scss" scoped>
.organizations {
  &__container {
    padding-top: var(--padding-top-container);
    padding-left: $p30;
    padding-right: $p30;
    margin-bottom: 30px;

    font-family: var(--new-front-font-family);

    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p5;
    line-height: 31px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      display: flex;
      justify-content: center;
        font-size: 20px;
    }
  }

  &__sub {
      font-size: $h2;
      font-weight: 400;
      color: var(--new-front-secondary-font-color);
      //margin-bottom: $p30;
      margin-left: $p5;
      @media screen and (max-width: 768px) {
          font-size: 13px;
      }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;

    @media screen and (max-width: 768px) {
     align-items: start ;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    cursor: pointer;
  }

  &__sub-wrapper {
    display: flex;
  }

  &__body-container {
    display: flex;
    flex-direction: column;
    //background: transparent;

    @media screen and (max-width: 768px) {
      padding: 0;
      //box-shadow: 0 8px 50px rgba(10, 17, 105, 0.08);
      border-radius: 14px;
      //background: var(--secondary-color);
    }
  }
}
</style>
