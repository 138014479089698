<script>
  import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
  import {mapMutations} from "vuex";
  export default {
    name: "DashboardWalletSelect",
    components: {
      BhSelectNew
    },
    data() {
      return {
        list: [
          {
            label: '$USD',
            value: 'usd',
            id: 'usd'
          },
          {
            label: '€EURO',
            value: 'eur',
            id: 'eur'
          }
        ],
        value: {
          label: '$USD',
          value: 'usd',
          id: 'usd'
        },
      }
    },
    methods: {
      ...mapMutations('dashboard', ['setCurrentWallet']),
      currentWallet (value) {
        this.setCurrentWallet(value.value)
      }
    }
  }
</script>

<template>
<bh-select-new class="select" v-model="value" :list="list" @input="currentWallet">
  <template #info="item">
    <div class="item">
      <div class="icon" v-if="item.value === 'usd'">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_20888_77017)">
            <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#F0F0F0"/>
            <path d="M9.564 9.99992H19.9988C19.9988 9.09734 19.8785 8.22297 19.6543 7.39121H9.564V9.99992ZM9.564 4.78254H18.531C17.9195 3.78534 17.1387 2.90253 16.2236 2.17383H9.564V4.78254ZM9.99877 19.9999C12.3522 19.9999 14.5154 19.1865 16.2236 17.826H3.77393C5.48213 19.1865 7.64529 19.9999 9.99877 19.9999ZM1.4665 15.2173H18.531C19.0284 14.406 19.4067 13.5274 19.6543 12.6086H0.343262C0.590875 13.5274 0.969149 14.406 1.4665 15.2173Z" fill="#D80027"/>
            <path d="M4.63219 1.56164H5.54348L4.69582 2.17746L5.01961 3.17391L4.17199 2.55809L3.32437 3.17391L3.60406 2.31309C2.85635 2.93597 2.20303 3.66409 1.66453 4.47469H1.95652L1.41695 4.86668C1.33281 5.0071 1.25214 5.14956 1.175 5.29395L1.43266 6.08695L0.951953 5.7377C0.831984 5.9917 0.722875 6.2507 0.624922 6.51398L0.908789 7.38773H1.95652L1.10887 8.00356L1.43266 9L0.585039 8.38418L0.0773047 8.75309C0.0258214 9.16673 3.78062e-06 9.58317 0 10H10V0C8.02453 0 6.18301 0.573047 4.63219 1.56164ZM5.01961 9L4.17199 8.38418L3.32437 9L3.64816 8.00356L2.80051 7.38773H3.84824L4.17199 6.39129L4.49574 7.38773H5.54348L4.69582 8.00356L5.01961 9ZM4.69582 5.09051L5.01961 6.08695L4.17199 5.47113L3.32437 6.08695L3.64816 5.09051L2.80051 4.47469H3.84824L4.17199 3.47824L4.49574 4.47469H5.54348L4.69582 5.09051ZM8.60656 9L7.75895 8.38418L6.91133 9L7.23512 8.00356L6.38746 7.38773H7.4352L7.75895 6.39129L8.0827 7.38773H9.13043L8.28277 8.00356L8.60656 9ZM8.28277 5.09051L8.60656 6.08695L7.75895 5.47113L6.91133 6.08695L7.23512 5.09051L6.38746 4.47469H7.4352L7.75895 3.47824L8.0827 4.47469H9.13043L8.28277 5.09051ZM8.28277 2.17746L8.60656 3.17391L7.75895 2.55809L6.91133 3.17391L7.23512 2.17746L6.38746 1.56164H7.4352L7.75895 0.565195L8.0827 1.56164H9.13043L8.28277 2.17746Z" fill="#0052B4"/>
          </g>
          <defs>
            <clipPath id="clip0_20888_77017">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="icon" v-else>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_20888_77053)">
            <path d="M20 10C20 15.5213 15.5254 19.9975 10.0046 20H9.89667C9.41192 19.995 8.92817 19.955 8.44917 19.8804C8.05693 19.8198 7.66872 19.7355 7.28667 19.6279C5.18907 19.036 3.34217 17.7752 2.02688 16.0373C0.711591 14.2994 -0.00011743 12.1795 1.45329e-08 10C1.45329e-08 4.47792 4.47583 3.98945e-06 10 3.98945e-06H10.0046C11.365 -0.00121305 12.7113 0.276038 13.9606 0.814688C15.2099 1.35334 16.3357 2.14199 17.2688 3.13209C19.0236 4.98811 20.0009 7.44574 20 10Z" fill="white"/>
            <path d="M7.47605 7.02629V7.47629H6.0223L1.93896 4.08087C2.19136 3.73754 2.46554 3.41075 2.7598 3.10254L7.47605 7.02629ZM18.0735 4.09921L14.014 7.47421H12.5235V7.05671L17.256 3.11962L17.2685 3.13254C17.5413 3.4201 17.7965 3.72385 18.0327 4.04212L18.0735 4.09921ZM7.47605 12.5238V12.9738L2.7598 16.8984C2.71813 16.8567 2.67646 16.8117 2.6373 16.768C2.38921 16.4984 2.15621 16.2153 1.93938 15.92L6.02272 12.5238H7.47605ZM18.074 15.9013C17.8226 16.2449 17.5495 16.5721 17.2565 16.8809L12.5235 12.9434V12.5238H14.014L18.074 15.9013Z" fill="#C8102E"/>
            <path d="M3.99379 7.47587H0.321289C0.529926 6.67577 0.837874 5.90491 1.23796 5.18129L3.99379 7.47587ZM3.91087 12.5234L1.20837 14.7709C0.822174 14.0607 0.524268 13.3059 0.321289 12.5234H3.91087ZM7.47629 14.6367V19.6784C7.41296 19.6621 7.35129 19.645 7.28671 19.6271C5.97639 19.2581 4.75558 18.6245 3.69962 17.7655L7.47629 14.6367ZM7.47629 0.321289V5.28379L3.75587 2.18879C4.85071 1.31131 6.11851 0.674928 7.47629 0.321289ZM16.3013 2.23546L12.5238 5.36671V0.321706L12.5809 0.336289C13.9413 0.699207 15.2094 1.3465 16.3013 2.23546ZM19.6788 7.47587H16.0955L18.7917 5.23254C19.1775 5.94146 19.4754 6.6948 19.6788 7.47587ZM16.3334 17.7409C15.2192 18.6544 13.9202 19.3156 12.5259 19.6788V14.5734L16.3334 17.7409ZM19.6788 12.5234C19.4802 13.287 19.1913 14.0243 18.8184 14.7196L16.1788 12.5234H19.6788Z" fill="#012169"/>
            <path d="M20 9.89542V10.095C19.9957 10.5613 19.9591 11.0267 19.8904 11.4879H11.4879V19.89C11.0296 19.9584 10.5671 19.9951 10.1038 20H9.89542C9.43189 19.9952 8.96926 19.9583 8.51083 19.8896V11.4879H0.11C0.041626 11.029 0.00487275 10.566 0 10.1021L0 9.905C0.00431054 9.43858 0.040926 8.97302 0.109583 8.51167H8.51208V0.11C8.9484 0.0447683 9.38849 0.00802389 9.82958 0L10.1521 0C10.573 0.00601834 10.9932 0.038583 11.41 0.0975L11.4879 0.109167V8.51167H19.89C19.9584 8.96985 19.9951 9.43219 20 9.89542Z" fill="#C8102E"/>
          </g>
          <defs>
            <clipPath id="clip0_20888_77053">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </div>
      <div class="label">{{ item.label }}</div>
    </div>
  </template>
  <template #selected="item">
    <div class="item">
      <div class="icon" v-if="item.value === 'usd'">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_20888_77017)">
            <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#F0F0F0"/>
            <path d="M9.564 9.99992H19.9988C19.9988 9.09734 19.8785 8.22297 19.6543 7.39121H9.564V9.99992ZM9.564 4.78254H18.531C17.9195 3.78534 17.1387 2.90253 16.2236 2.17383H9.564V4.78254ZM9.99877 19.9999C12.3522 19.9999 14.5154 19.1865 16.2236 17.826H3.77393C5.48213 19.1865 7.64529 19.9999 9.99877 19.9999ZM1.4665 15.2173H18.531C19.0284 14.406 19.4067 13.5274 19.6543 12.6086H0.343262C0.590875 13.5274 0.969149 14.406 1.4665 15.2173Z" fill="#D80027"/>
            <path d="M4.63219 1.56164H5.54348L4.69582 2.17746L5.01961 3.17391L4.17199 2.55809L3.32437 3.17391L3.60406 2.31309C2.85635 2.93597 2.20303 3.66409 1.66453 4.47469H1.95652L1.41695 4.86668C1.33281 5.0071 1.25214 5.14956 1.175 5.29395L1.43266 6.08695L0.951953 5.7377C0.831984 5.9917 0.722875 6.2507 0.624922 6.51398L0.908789 7.38773H1.95652L1.10887 8.00356L1.43266 9L0.585039 8.38418L0.0773047 8.75309C0.0258214 9.16673 3.78062e-06 9.58317 0 10H10V0C8.02453 0 6.18301 0.573047 4.63219 1.56164ZM5.01961 9L4.17199 8.38418L3.32437 9L3.64816 8.00356L2.80051 7.38773H3.84824L4.17199 6.39129L4.49574 7.38773H5.54348L4.69582 8.00356L5.01961 9ZM4.69582 5.09051L5.01961 6.08695L4.17199 5.47113L3.32437 6.08695L3.64816 5.09051L2.80051 4.47469H3.84824L4.17199 3.47824L4.49574 4.47469H5.54348L4.69582 5.09051ZM8.60656 9L7.75895 8.38418L6.91133 9L7.23512 8.00356L6.38746 7.38773H7.4352L7.75895 6.39129L8.0827 7.38773H9.13043L8.28277 8.00356L8.60656 9ZM8.28277 5.09051L8.60656 6.08695L7.75895 5.47113L6.91133 6.08695L7.23512 5.09051L6.38746 4.47469H7.4352L7.75895 3.47824L8.0827 4.47469H9.13043L8.28277 5.09051ZM8.28277 2.17746L8.60656 3.17391L7.75895 2.55809L6.91133 3.17391L7.23512 2.17746L6.38746 1.56164H7.4352L7.75895 0.565195L8.0827 1.56164H9.13043L8.28277 2.17746Z" fill="#0052B4"/>
          </g>
          <defs>
            <clipPath id="clip0_20888_77017">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="icon" v-else>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_20888_77053)">
            <path d="M20 10C20 15.5213 15.5254 19.9975 10.0046 20H9.89667C9.41192 19.995 8.92817 19.955 8.44917 19.8804C8.05693 19.8198 7.66872 19.7355 7.28667 19.6279C5.18907 19.036 3.34217 17.7752 2.02688 16.0373C0.711591 14.2994 -0.00011743 12.1795 1.45329e-08 10C1.45329e-08 4.47792 4.47583 3.98945e-06 10 3.98945e-06H10.0046C11.365 -0.00121305 12.7113 0.276038 13.9606 0.814688C15.2099 1.35334 16.3357 2.14199 17.2688 3.13209C19.0236 4.98811 20.0009 7.44574 20 10Z" fill="white"/>
            <path d="M7.47605 7.02629V7.47629H6.0223L1.93896 4.08087C2.19136 3.73754 2.46554 3.41075 2.7598 3.10254L7.47605 7.02629ZM18.0735 4.09921L14.014 7.47421H12.5235V7.05671L17.256 3.11962L17.2685 3.13254C17.5413 3.4201 17.7965 3.72385 18.0327 4.04212L18.0735 4.09921ZM7.47605 12.5238V12.9738L2.7598 16.8984C2.71813 16.8567 2.67646 16.8117 2.6373 16.768C2.38921 16.4984 2.15621 16.2153 1.93938 15.92L6.02272 12.5238H7.47605ZM18.074 15.9013C17.8226 16.2449 17.5495 16.5721 17.2565 16.8809L12.5235 12.9434V12.5238H14.014L18.074 15.9013Z" fill="#C8102E"/>
            <path d="M3.99379 7.47587H0.321289C0.529926 6.67577 0.837874 5.90491 1.23796 5.18129L3.99379 7.47587ZM3.91087 12.5234L1.20837 14.7709C0.822174 14.0607 0.524268 13.3059 0.321289 12.5234H3.91087ZM7.47629 14.6367V19.6784C7.41296 19.6621 7.35129 19.645 7.28671 19.6271C5.97639 19.2581 4.75558 18.6245 3.69962 17.7655L7.47629 14.6367ZM7.47629 0.321289V5.28379L3.75587 2.18879C4.85071 1.31131 6.11851 0.674928 7.47629 0.321289ZM16.3013 2.23546L12.5238 5.36671V0.321706L12.5809 0.336289C13.9413 0.699207 15.2094 1.3465 16.3013 2.23546ZM19.6788 7.47587H16.0955L18.7917 5.23254C19.1775 5.94146 19.4754 6.6948 19.6788 7.47587ZM16.3334 17.7409C15.2192 18.6544 13.9202 19.3156 12.5259 19.6788V14.5734L16.3334 17.7409ZM19.6788 12.5234C19.4802 13.287 19.1913 14.0243 18.8184 14.7196L16.1788 12.5234H19.6788Z" fill="#012169"/>
            <path d="M20 9.89542V10.095C19.9957 10.5613 19.9591 11.0267 19.8904 11.4879H11.4879V19.89C11.0296 19.9584 10.5671 19.9951 10.1038 20H9.89542C9.43189 19.9952 8.96926 19.9583 8.51083 19.8896V11.4879H0.11C0.041626 11.029 0.00487275 10.566 0 10.1021L0 9.905C0.00431054 9.43858 0.040926 8.97302 0.109583 8.51167H8.51208V0.11C8.9484 0.0447683 9.38849 0.00802389 9.82958 0L10.1521 0C10.573 0.00601834 10.9932 0.038583 11.41 0.0975L11.4879 0.109167V8.51167H19.89C19.9584 8.96985 19.9951 9.43219 20 9.89542Z" fill="#C8102E"/>
          </g>
          <defs>
            <clipPath id="clip0_20888_77053">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </div>
      <div class="label">{{ item.label }}</div>
    </div>
  </template>
</bh-select-new>
</template>

<style scoped lang="scss">
.select {
  width: 137px;
}
.item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.label {
  color: var(--text-text-white, #FFF);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 0;
}
.icon {
  height: 20px;
  width: 20px;
}
::v-deep {
  .options-list {
    padding: 0 !important;
  }
  .options-list-item {
    border-radius: 0 !important;
    padding: 10px 20px !important;
    height: 48px !important;
  }
  .options-list-item:hover {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .new-bh-select-wrapper .input-wrapper .options-list .options-list-item.active {
    background: var(--bg-background-primary-purple) !important;
    .label {
      color: var(--text-text-white) !important;
    }
  }
  .options-list-item {
    display: flex;
    align-items: center;
  }
}
</style>
