export const state = {
    dates: [],
    orders: [],
    transactionsCount: [],
    payments: [],
    balances: [],
    transactions: [],
    deposit: [],
    withdrawal: [],
    isInfoOpened: false,
    infoPayload: {},
    dashboardRawData: {},
    currentWallet: 'usd',
    currentInterval: 14,
};
