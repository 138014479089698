import changeOrganizationOwnerMutation from "/public/graphQl/mutations/changeOrganizationOwner.js";
import {reject} from "lodash";
import {getErrorMessage} from "@/public/filters";

export const changeOrganizationOwner = async ({commit, dispatch, rootGetters}, payload) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    let variables = {
        organizationId,
        newOwnerId: payload.id,
        otpToken: payload.otpToken,
    };
    try {
        commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'mutation',
            gql: changeOrganizationOwnerMutation,
            variables,
        }, {root: true});
    } catch (error) {
        const {
            data: {
                changeOrganizationOwner: {
                    traceId,
                },
            },
        } = currenciesData;
        return traceId;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }
    if (currenciesData.graphQLErrors) {
        if (currenciesData.graphQLErrors[0].extensions.code === "INCORRECT_2FA") {
            throw {
                message: getErrorMessage(currenciesData.graphQLErrors[0].extensions.code),
                twoFa: true
            }
        }
    }

    const {
        data: {
            changeOrganizationOwner: {
                traceId,
                code
            },
        },
    } = currenciesData;


    if (code === 2009) {
        // return traceId
        throw {
            message: traceId,
            twoFa: false
        }
    }


    return true;
};
