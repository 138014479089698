<template>
  <div class="new-bh-select-wrapper">
    <div v-if="label" class="label">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
    <div
      class="input-wrapper"
      :class="{ expanded }"
    >
      <div
        :id="`select-${_uid}`"
        class="input"
        :class="{ 'filled': valueModel && clearable }"
      >
        <div
          v-if="!valueModel"
          class="placeholder"
        >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </div>
        <div
          v-else
          class="selected"
        >
          <slot
            name="selected"
            v-bind="valueModel"
          >
            <div v-if="!multiple">
              {{ valueModel.label || valueModel.id }}
            </div>
            <div v-else>
              <span>
                {{ valueModel.label || valueModel.id }}
              </span>
              <span
                v-if="value.length > 1"
                style="padding-left: 5px"
              >
                +{{value.length - 1}}
              </span>
            </div>
          </slot>
        </div>
        <svg
            class="chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd" d="M0.228 1.635C0.085 1.462 0 1.241 0 1C0 0.448 0.448 0 1 0H7C7.552 0 8 0.448 8 1C8 1.241 7.915 1.462 7.772 1.635L4.808 5.589C4.626 5.838 4.332 6 4 6C3.668 6 3.374 5.838 3.192 5.589L0.228 1.635Z"
            :fill="themeStatus === 'dark' ? 'white' : '#333333'"
          />
        </svg>
        <svg
          v-if="clearable"
          class="clear"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          @click="clear"
        >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.96967 13.0303C0.676777 12.7374 0.676777 12.2626 0.96967 11.9697L5.93934 7L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L7 5.93934L11.9697 0.969671C12.2626 0.676777 12.7374 0.676777 13.0303 0.969671C13.3232 1.26256 13.3232 1.73744 13.0303 2.03033L8.06066 7L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L7 8.06066L2.03033 13.0303C1.73744 13.3232 1.26256 13.3232 0.96967 13.0303Z"
            fill="#8F949A"
          />
        </svg>
      </div>
      <slot name="options-list">
        <div
          v-if="expanded && list.length"
          class="options-list"
          :class="{ flat }"
        >
          <div
            v-if="multiple"
            class="options-list-header"
          >
            <div class="check-all">
              <div
                :id="`check-all-${_uid}`"
                class="checkbox"
                :class="{ 'active': checkedAll }"
                @click="checkAll"
              >
                <svg
                    v-if="checkedAll"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="14"
                    viewBox="0 0 21 14"
                    fill="none"
                    style="pointer-events: none"
                >
                  <path
                      d="M15.3102 0.560548C15.9326 -0.0618493 16.9417 -0.0618493 17.5641 0.560548C18.1789 1.17531 18.1864 2.16736 17.5867 2.79138L9.10331 13.3957C9.09107 13.411 9.07799 13.4256 9.06413 13.4395C8.44173 14.0619 7.43262 14.0619 6.81023 13.4395L1.18653 7.81575C0.564127 7.19335 0.564127 6.18425 1.18653 5.56185C1.80892 4.93945 2.81803 4.93945 3.44043 5.56185L7.88916 10.0106L15.268 0.608222C15.2811 0.5915 15.2952 0.575579 15.3102 0.560548Z"
                      fill="white"
                  />
                </svg>
              </div>
              <span class="check-all-label"> {{ $t('analytics.filters.wallets.select-all') }} </span>
            </div>
            <div class="counter">
              {{ counterText }}
            </div>
          </div>
          <div
            :id="`options-item-${_uid}`"
            v-for="(item, key) in list"
            class="options-list-item"
            :class="{ 'active': valueModel && checkInvite(item), 'gap': gap && key !== list.length - 1, 'bordered-options': multiple }"
            :key="key"
            @click="valueModel = item"
          >
            <slot name="option">
              <div
                  v-if="multiple"
                  class="prepend-info"
                  style="pointer-events: none"
              >
                <div
                    class="checkbox"
                    :class="{ 'active': valueModel && checkInvite(item) }"
                    @click="valueModel = item"
                >
                  <svg
                      v-if="valueModel && checkInvite(item)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 21 14"
                      fill="none"
                  >
                    <path
                        d="M15.3102 0.560548C15.9326 -0.0618493 16.9417 -0.0618493 17.5641 0.560548C18.1789 1.17531 18.1864 2.16736 17.5867 2.79138L9.10331 13.3957C9.09107 13.411 9.07799 13.4256 9.06413 13.4395C8.44173 14.0619 7.43262 14.0619 6.81023 13.4395L1.18653 7.81575C0.564127 7.19335 0.564127 6.18425 1.18653 5.56185C1.80892 4.93945 2.81803 4.93945 3.44043 5.56185L7.88916 10.0106L15.268 0.608222C15.2811 0.5915 15.2952 0.575579 15.3102 0.560548Z"
                        fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="info"
                style="pointer-events: none"
              >
                <slot
                  name="info"
                  v-bind="item"
                >
                  {{ item.label || item.id }}
                </slot>
              </div>
              <div
                v-if="!multiple"
                class="action"
              >
                <slot
                  name="action"
                  v-bind="item"
                >
                  <svg
                    v-if="valueModel && checkInvite(item)"
                    class="check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                  >
                    <path d="M1 4L3.82843 6.82842L9.48528 1.17157" stroke="#744DB2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </slot>
              </div>
            </slot>
          </div>
        </div>
        <div
          v-else-if="expanded"
          class="options-list empty"
        >
          {{ $t('common.empty') }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import AtSelect from 'at-ui/src/components/select';
import {mapState} from "vuex";

export default {
  name: "bh-select",
  components: {
    AtSelect
  },
  data() {
    return {
      expanded: false
    }
  },
  props: {
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    list: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: [Array, Object],
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за представление листа без паддингов - обычное представление списка
    flat: {
      type: Boolean,
      default: false
    },
    //пропс отвечает за паддинги между опшионсами
    gap: {
      type: Boolean,
      default: false
    },
    //пока заготовка под мультиселект - множетсвенный выбор не реализован до конца
    multiple: {
      type: Boolean,
      default: false
    },
    //пока заготовка под мультиселект - множетсвенный выбор не реализован до конца
    checkAllLoop: {
      type: Boolean,
      default: false
    },
    uniqKey: {
      type: String,
      default: 'id'
    },
  },
  watch: {
    uncheckedAll: {
      handler(v) {
        if (!!v && this.checkAllLoop) {
          this.checkAll()
        }
      },
      immediate: true
    }
  },
  created() {
    this.addOuterClickListener()
  },
  computed: {
    ...mapState('app', {
      themeStatus: state => state.theme
    }),
    uncheckedAll() {
      //проверка того что все чекбоксы сняты
      return !this.value.length
    },
    checkedAll() {
      return this.value.length === this.list.length
    },
    valueModel: {
      get({ value, multiple }) {
        if ( multiple ) {
          return !!value.length ? value[0] : undefined
        } else {
          return value
        }
      },
      set(v) {
        if ( this.multiple ) {
          if (!v) {
            this.$emit('input', [])
          } else if ( this.checkInvite(v) ) {
            this.$emit('input', this.value.filter((i) => v[this.uniqKey] !== i[this.uniqKey]))
          } else {
            this.$emit('input', [...this.value, v])
          }
        } else {
          this.$emit('input', v)
        }
      }
    },
    counterText() {
      const { value, list } = this
      return this.$t('analytics.filters.wallets.selected-label', { selected: value.length, length: list.length })
    }
  },
  destroyed() {
    document.removeEventListener("click", this.expandToggler, false);
  },
  methods: {
    addOuterClickListener() {
      document.addEventListener('click', this.expandToggler, false)
    },
    clear() {
      this.valueModel = undefined
    },
    checkInvite(item) {
      return this.multiple ? this.value.some((i) => item[this.uniqKey] === i[this.uniqKey]) : item[this.uniqKey] === this.value[this.uniqKey]
    },
    expandToggler(el) {
      let { _uid, multiple } = this

      if ( el.target.id === `select-${this._uid}` ) {
        this.expanded = !this.expanded

        return
      }

      if ( el.target.id === `check-all-${_uid}` ) {
        this.expanded = true

        return
      }

      if ( el.target.id === `options-item-${_uid}` ) {
        this.expanded = multiple

        return
      }

      this.expanded = false
    },
    checkAll() {
      if (!this.checkedAll) {
        this.$emit('input', this.list)
      } else {
        this.$emit('input', [])
      }
    }
  }
}
</script>

<style lang="scss">
  .new-bh-select-wrapper {
    font-family: var(--new-front-font-family);

    .label {
      font-style: normal;
      font-weight: 400;
      font-size: $h4;
      line-height: $h4;

      padding-bottom: 5px;

      color: var(--new-front-input-label-font-color)!important;
    }

    .input-wrapper {
      position: relative;

      .input {
        display: flex;
        align-items: center;

        width: 100%;

        min-height: 47px;
        border: 1px solid var(--new-front-input-border);
        border-radius: 12px;
        padding: 0 30px 0 20px;

        cursor: pointer;

        font-family: var(--new-front-font-family);

        font-style: normal;
        font-weight: 500;
        font-size: $h4;
        line-height: 47px;

        .selected {
          pointer-events: none;
          color: var(--new-front-input-font-color);
        }

        .placeholder {
          pointer-events: none;
          color: var(--new-front-input-placeholder-color);
        }

        &.filled {
          &:hover {
            svg.chevron {
              display: none;
            }
            svg.clear {
              display: flex;
            }
          }
        }


        svg.chevron {
          position: absolute;
          top: 50%;
          right: 20px;

          display: block;

          transition: all .3s;
          transform: translateY(-50%) rotate(0);

          width: 10px;

          pointer-events: none;
        }

        svg.clear {
          display: none;

          position: absolute;
          top: 50%;
          right: 20px;

          transform: translateY(-50%);

          width: 10px;
        }
      }


      .options-list {
        transition: all .3s;

        position: absolute;
        top: 57px;
        left: 0;

        opacity: 0;
        pointer-events: none;

        width: 100%;
        max-height: 500px;

        background: var(--new-front-select-option-list-bg);

        border: 1px solid var(--new-front-input-border);
        border-radius: 12px;

        padding: 20px;

        z-index: 5;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 0; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: transparent; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background: transparent; /* color of the tracking area */
          width: 0;
        }

        .options-list-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 20px;

          padding: 0 15px;

          font-size: $h4;
          color: var(--new-front-input-placeholder-color);

          .check-all {
            display: flex;
            align-items: center;

            .check-all-label {
              margin-left: 12px;
            }
          }
        }

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          height: 20px;
          width: 20px;

          border: 1px solid var(--new-front-input-border);
          border-radius: 5px;

          &.active {
            padding-left: 1px;
            background-color: var(--new-front-bg-purple);
          }

          svg {
            width: 15px;
            height: 15px;
          }
        }

        .options-list-item {
          width: 100%;

          font-style: normal;
          font-weight: 500;
          font-size: $h4;

          padding: 8px 13px 8px 20px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;

          border-radius: 12px;

          color: var(--new-front-select-option-list-item-font-color);

          &:hover {
            transition: all .3s;
            background: var(--new-front-select-option-hover);
            color: var(--new-front-select-option-list-item-font-color);
          }

          &.active {
            background: var(--new-front-select-option-active);
          }

          &.bordered-options {
            border: 1px solid var(--new-front-input-border);
          }

          &.gap {
            margin-bottom: 20px;
          }

          .prepend-info {
            margin-right: 10px;
          }

          .info {
            width: -webkit-fill-available;
          }

          .action {
            width: fit-content;
            white-space: nowrap;

            svg.check {
              width: 13px;
            }
          }
        }

        &.flat {
          padding: 0;
          .options-list-item {
            box-shadow: none;
            border-radius: 0;
            margin: 0;
          }
        }

        &.empty {
          padding: 20px;
          color: #8F949A;
        }
      }

      &.expanded {
        svg.chevron {
          transition: all .3s;

          transform: translateY(-50%) rotate(180deg);
        }

        .options-list {
          opacity: 1;
          pointer-events: visible;
        }
      }
    }

    @include below_phone() {
      .input-wrapper {
        .options-list {
          padding: 15px;
        }
      }
    }
  }
</style>
