<template>
    <StoreModal
            class="remove-user-modal"
            name="RemoveUserModal"
            @closeModal="handleCancelCreate"
    >
        <template #body>
            <div class="modal">
                <div class="modal__icon">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_11714_132882)">
                            <rect width="60" height="60" rx="10" fill="#DAC066"/>
                            <path d="M24.8098 18.2197C24.9504 18.079 25.1412 18 25.3401 18H34.6599C34.8588 18 35.0496 18.079 35.1902 18.2197L41.7803 24.8098C41.921 24.9504 42 25.1412 42 25.3401V34.6599C42 34.8588 41.921 35.0496 41.7803 35.1902L35.1902 41.7803C35.0496 41.921 34.8588 42 34.6599 42H25.3401C25.1412 42 24.9504 41.921 24.8098 41.7803L18.2197 35.1902C18.079 35.0496 18 34.8588 18 34.6599V25.3401C18 25.1412 18.079 24.9504 18.2197 24.8098L24.8098 18.2197ZM25.6508 19.5L19.5 25.6508V34.3492L25.6508 40.5H34.3492L40.5 34.3492V25.6508L34.3492 19.5H25.6508Z" fill="white"/>
                            <path d="M28.5023 34.5C28.5023 33.6716 29.1739 33 30.0023 33C30.8307 33 31.5023 33.6716 31.5023 34.5C31.5023 35.3284 30.8307 36 30.0023 36C29.1739 36 28.5023 35.3284 28.5023 34.5Z" fill="white"/>
                            <path d="M28.6493 25.4926C28.5693 24.6934 29.1969 24 30 24C30.8031 24 31.4307 24.6934 31.3507 25.4926L30.8246 30.7537C30.7823 31.1774 30.4258 31.5 30 31.5C29.5742 31.5 29.2177 31.1774 29.1754 30.7537L28.6493 25.4926Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_11714_132882">
                                <rect width="60" height="60" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div class="modal__title">
                {{ $t('organization.modal_delete_title') }}
                </div>
                <p class="remove-user-modal__message">
                    {{$t('organization.modal_delete_message_user')}}
                    <span v-if="modalPayload && isOpen">{{ modalPayload.user.name }}</span>?
                </p>
            </div>
        </template>

        <template #footer>
            <div class="modal__footer">
                <base-button :label="$t('api_keys.close')" type="secondary" @click="handleCancelCreate" />
                <base-button
                        type="primary"
                        :label="$t('api_keys.delete')"
                        @click="handleRemoveRequest"
                />

            </div>
        </template>

    </StoreModal>
</template>

<script>

import {mapGetters, mapActions, mapState} from 'vuex';

import AtModal from 'at-ui/src/components/modal';
import LoadingSpinner from "/public/components/elements/LoadingSpinner";
import AtButton from 'at-ui/src/components/button';
import BaseModal from "../BaseModal";
import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";

export default {
    name: 'RemoveUserModal',
    components: {
        BaseButton,
        BaseModal,
        AtModal,
        LoadingSpinner,
        AtButton,
        StoreModal: () => import(/* webpackChunkName: "/public/components/modals/StoreModal" */ '/public/components/modals/StoreModal'),

    },
    data() {
        return {
            isLoading: false,
            isSuccess: false,
        };
    },
    computed: {
        ...mapGetters('app', [
            'isOpenModal',
        ]),
        ...mapState('app', {
            modalPayload: state => state.modal.payload,
        }),
        isOpen() {
            const {
                $options: {
                    name,
                },
                isOpenModal,
            } = this;

            return isOpenModal(name);
        },
    },
    methods: {
        ...mapActions('app', [
            'closeModal',
        ]),
        ...mapActions('organizations', [
            'getOrganizationUsers',
            'deleteOrganizationUser',
        ]),

        clearAllData() {
            this.isLoading = false;
            this.isSuccess = false;
        },

        handleRemoveRequest() {
            this.deleteOrganizationUser({
                userId: this.modalPayload.user.id,
            }).then(code => {
                this.closeModal();
                this.clearAllData();
                this.getOrganizationUsers();
            }).catch(code => {

            });
        },

        handleCancelCreate() {
            const {
                closeModal,
                clearAllData,
            } = this;

            closeModal();
            this.$store.commit('organizations/setSelectedRole', null);
            this.$store.commit('organizations/setDetailRoleId', null);

            setTimeout(() => {
                clearAllData();
            }, 500);
        },
    },
};

</script>

<style lang="scss" scoped>
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
        margin-top: 30px;
        font-weight: 500;
        color: var(--new-front-primary-font-color);
        font-size: $h1;
    }
    &__subtitle {
        margin-top: 20px;
        font-size: $h4;
        color: var(--new-front-secondary-font-color);
    }
    &__footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

.remove-user-modal {
    font-family: $Gilroy;
  &__full-width-btn {
    width: 100%;
  }

  &__message {
      margin-top: 20px;
      font-size: $h4;
      color: var(--new-front-secondary-font-color);
      font-weight: 500;
    line-height: 23px;
    text-align: center;
      margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }

    span {
        color: var(--new-front-primary-font-color);
    }
  }

  &__alias {
      font-family: $Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-font-color);
    margin-top: 25px;
  }
}

</style>
