<template>
<preloader
    v-if="isLoading"
    class="analytics__preloader"
/>
<div v-else class="analytics__container">
  <back-button />
  <Container
    class="analytics-body__container"
    overflow
  >
    <div class="filters">
      <div class="first-line">
        <button-select v-model="filters.period"/>
      </div>
      <div class="second-line">
        <div class="selects">
          <date-picker
            v-model="datePickerModel"
            class="date-select"
            :range="filters.period === 'period'"
            :close-method.sync="pickerClose"
          />
          <template v-if="!advancedBalancesRoute">
            <wallets
              v-model="filters.wallets"
              class="wallets-select"
            />
            <grouping
              v-model="filters.grouping"
              class="grouping-select"
            />
          </template>
        </div>
        <div class="actions">
          <base-button
              type="new-secondary"
              class="reload-btn"
              @click="reloadPersonsList"
          >
            <template #prepend>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9 2.15942C5.22205 2.15942 2.15942 5.22205 2.15942 9C2.15942 12.7779 5.22205 15.8406 9 15.8406C12.7776 15.8406 15.84 12.7785 15.8406 9.00104C15.8406 8.68087 16.1002 8.42137 16.4204 8.42142C16.7405 8.42146 17 8.68105 17 9.00121C16.9993 13.419 13.4178 17 9 17C4.58172 17 0.999999 13.4183 1 9C1 4.58172 4.58172 0.999999 9 1C10.6719 1 12.2629 1.51573 13.5883 2.44545L14.1649 1.78214C14.4687 1.4327 15.0393 1.56281 15.1616 2.00939L15.8514 4.52939C15.9607 4.92838 15.6289 5.31009 15.2185 5.25746L12.6271 4.92502C12.1678 4.86611 11.9595 4.31914 12.2633 3.9697L12.8228 3.32604C11.7101 2.57451 10.3883 2.15942 9 2.15942Z" fill="#6750A4" stroke="#6750A4"/>
              </svg>
            </template>
          </base-button>
          <downloader
              v-model="selectedDownloadType"
              class="file-downloader"
              type="secondary"
          >
            <template #icon>
              <svg
                  id="btn-select-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
              >
                <path
                    d="M8.72933 14.9529C8.33425 15.3743 7.66533 15.3743 7.27025 14.9529L0.924369 8.18392C0.32562 7.54525 0.778465 6.49998 1.65391 6.49998L4.49979 6.49998L4.49979 5.49998C4.49979 4.94769 4.94751 4.49998 5.49979 4.49998L10.4998 4.49998C11.0521 4.49998 11.4998 4.94769 11.4998 5.49998L11.4998 6.49998L14.3457 6.49998C15.2211 6.49998 15.674 7.54525 15.0752 8.18392L8.72933 14.9529Z"
                    fill="#744DB2"
                />
                <path
                    d="M11.4998 2.49998C11.4998 3.05226 11.0521 3.49998 10.4998 3.49998L5.49979 3.49998C4.94751 3.49998 4.49979 3.05226 4.49979 2.49998L4.49979 1.49998C4.49979 0.947692 4.94751 0.499977 5.49979 0.499977L10.4998 0.499978C11.0521 0.499978 11.4998 0.947693 11.4998 1.49998L11.4998 2.49998Z"
                    fill="#744DB2"
                />
              </svg>
            </template>
          </downloader>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <div
        v-if="isAnalyticsLoading"
        class="loader-block"
        style="min-height: 200px"
      >
        <new-spinner />
      </div>
      <template v-else>
        <template v-if="advancedBalancesRoute">
          <advanced-date-table v-if="filters.period === 'date'" />
          <advanced-period-table v-else />
        </template>
        <template v-else>
          <wallets-date-table
            v-if="filters.period === 'date'"
            :is-wallets-grouping="isWalletsGrouping"
          />
          <wallets-period-table
            v-else
            :is-wallets-grouping="isWalletsGrouping"
          />
        </template>
      </template>
    </div>
  </Container>
</div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import ButtonSelect from '/public/components/elements/analytics/selects/ButtonSelect';
import CommonInfoModal from "/public/components/modals/commonInfo";
import Container from '/public/components/common/Container';

import WalletsDateTable from "/public/components/elements/analytics/tables/Wallets/Date";
import WalletsPeriodTable from "/public/components/elements/analytics/tables/Wallets/Period";

import AdvancedDateTable from "/public/components/elements/analytics/tables/Advanced/Date";
import AdvancedPeriodTable from "/public/components/elements/analytics/tables/Advanced/Period";

import Preloader from '/public/components/common/preloader';

import Moment from "moment";

import { debounce } from "lodash"

export default {
  name: "Analytics",
  data() {
      return {
          filters: {
              period: 'date',
              grouping: 'wallets',
              wallets: [],
              date: []
          },
          selectedDownloadType: undefined,
          pickerClose: () => {}
      };
  },
  components: {
    BackButton: () => import(/* webpackChunkName: "/public/components/elements/analytics/BackButton" */ '/public/components/elements/analytics/BackButton'),
    BaseButton: () => import(/* webpackChunkName: "/public/components/common/uiKit/button/base/bg-secondary" */ '/public/components/common/uiKit/button/base/bg-secondary'),
    ButtonSelect,
    CommonInfoModal,
    Container,
    WalletsDateTable,
    WalletsPeriodTable,
    AdvancedDateTable,
    AdvancedPeriodTable,
    Downloader: () => import(/* webpackChunkName: "/public/components/elements/personal-addresses/Select/Downloader" */ '/public/components/elements/personal-addresses/Select/Downloader'),
    Grouping: () => import(/* webpackChunkName: "/public/components/elements/analytics/selects/Grouping" */ '/public/components/elements/analytics/selects/Grouping'),
    DatePicker: () => import(/* webpackChunkName: "/public/components/elements/analytics/selects/DatePicker" */ '/public/components/elements/analytics/selects/DatePicker'),
    Preloader,
    Wallets: () => import(/* webpackChunkName: "/public/components/elements/analytics/selects/Wallets" */ '/public/components/elements/analytics/selects/Wallets'),
    NewSpinner: () => import (/* webpackChunkName: "/public/components/common/uiKit/spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
  },
  watch: {
    'filters': {
      handler(v) {
        if (v.date.length) {
          this.$store.commit('analytics/setAnalyticsLoading', true);
          this.debounceGetAnalytics()
        }
      },
      deep: true
    },
    'filters.period': {
      handler() {
        this.pickerClose()
        this.setDefaultDate()
      },
      immediate: true
    },
    selectedDownloadType(v) {
      if (!!v) {
        const { advancedBalancesRoute } = this

        const payload = {
          "organizationId": this.getSelectedOrganization.id,
          "startDate": Moment(this.filters.date[0]).format()
        }

        if (this.filters.date[1]) {
          payload['endDate'] = Moment(this.filters.date[1]).format()
        }

        if (!advancedBalancesRoute) {
          payload['groupBy'] = this.filters.grouping === 'wallets' ? 'WALLET' : 'NETWORK'
          payload['type'] = this.filters.wallets
        }

        let url

        if (!advancedBalancesRoute) {
          // для кошельковой аналитики
          url = `/downloads/${v.id}-address-snapshots?_=` + encodeURIComponent(JSON.stringify(payload))
        } else {
          // для аналитики авансовых балансов
          url = `/downloads/${v.id}-adv-balance-analytics?_=` + encodeURIComponent(JSON.stringify(payload))
        }

        window.open(url)
        this.selectedDownloadType = undefined
      }
    }
  },
  computed: {
    ...mapState('app', ['isLoading']),
    ...mapState('analytics', [
      'isAnalyticsInit',
      'isAnalyticsLoading',
      'analytics'
    ]),
    ...mapGetters('app', [
      'getSelectedOrganization'
    ]),
      ...mapGetters('organizations', ['userRoleRules']),
    debounceGetAnalytics: ({ getAnalytics }) => debounce(getAnalytics, 200),
    datePickerModel: {
      get({ filters }) {
        return filters.date
      },
      set(v) {
        this.$set(this.filters, 'date', v)
      }
    },
    section() {
      const { wallet } = this.$route.params
      return wallet;
    },
    advancedBalancesRoute({ section }) {
      return section === 'ADVANCED'
    },
    isWalletsGrouping({ filters }) {
      return filters.grouping === 'wallets'
    }
  },
  created() {
      const isAllowed = this.userRoleRules['analytics'].isAllowed;
      const isViewAllowed = this.userRoleRules['analytics'].entities.view_analytics;

      if (!isAllowed || !isViewAllowed) {
          this.$Message.error(this.$t('rules.not_allowed'));
          this.$router.push('/merchant/dashboard');
      }
      if (this.section && !this.advancedBalancesRoute) {
      this.filters.wallets.push(this.section)
    }
  },
  methods: {
    ...mapActions('analytics', [
      'getAddressAnalyticsByNetwork',
      'getAddressAnalyticsByWallet',
      'getAdvAnalytics'
    ]),
    async getAnalytics() {
      const { advancedBalancesRoute } = this

      const payload = {
        'startDate': this.filters.date[0],
      }

      if (this.filters.date[1]) {
        payload['endDate'] = this.filters.date[1]
      }

      if (!advancedBalancesRoute) {
        payload['type'] = this.filters.wallets

        if (this.isWalletsGrouping) {
          await this.getAddressAnalyticsByWallet(payload)
        } else {
          await this.getAddressAnalyticsByNetwork(payload)
        }
      } else {
        await this.getAdvAnalytics(payload)
      }
    },
    reloadPersonsList() {
      this.getAnalytics()
    },
    setDefaultDate() {
      const startOfDay =(day)=> Moment(day).set({ 'hour': 0, 'minute': 0, 'second': 0 }).format()
      const endOfDay =(day)=> Moment(day).set({ 'hour': 23, 'minute': 59, 'second': 59 }).format()

      if (this.filters.period === 'period') {
        this.$set(this.filters.date, 0, Moment( startOfDay(new Date) ).subtract(1, 'months').format())
        this.$set(this.filters.date, 1, endOfDay(new Date))
      } else {
        this.$set(this.filters, 'date', [ startOfDay(new Date) ])
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.analytics {
  &__preloader {
    top: 0;
  }

  &__container {
    max-width: 77vw;
    padding: 0 30px 30px 30px;

    @include below_1180() {
      max-width: 100vw;
    }

    .analytics-body__container {
      background: var(--new-front-bg-secondary);

      padding: 0 30px 30px 30px;

      .filters {
        .first-line, .second-line {
          padding: 20px 0;
        }
        .first-line {
          border-bottom: 1px solid var(--new-front-divider-color);
        }
        .second-line {
          display: flex;
          align-items: start;
          justify-content: space-between;

          @include below_880() {
            .selects {
              .date-select, .wallets-select, .grouping-select {
                width: 100%!important;
              }
            }
          }

          @include below_phone() {
            flex-wrap: wrap;

            .actions {
              margin: 20px 0 0 0!important;
            }
            .selects {
              .wallets-select {
                min-width: 298px!important;
              }
            }
          }

          .selects {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: start;

            flex-wrap: wrap;

            gap: 20px;

            .date-select {
              width: 250px;
              min-width: 250px;
            }
            .wallets-select {
              width: 320px;
              min-width: 320px;
            }
            .grouping-select {
              width: 250px;
              min-width: 250px;

            }
          }
          .actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            gap: 10px;

            flex-wrap: nowrap;

            width: 115px;

            margin-left: 20px;

            .reload-btn {
              width: 48px;
            }
            .file-downloader {
              width: 48px;
            }
          }
        }
      }
      .table-wrapper {
        max-height: 705px;
        overflow: scroll;

        .loader-block {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        &::-webkit-scrollbar {
          height: 0;
          width: 0; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          width: 0;
          height: 0;
          background: transparent; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background: transparent; /* color of the tracking area */
          width: 0;
          height: 0;
        }
      }

      @include below_phone() {
        padding: 0 10px 10px 10px;
      }
    }

    @include below_phone() {
      padding: 0 10px 10px 10px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    margin-bottom: 13px;
    font-family: $Gilroy;
    font-style: normal;
    font-weight: 400;
    font-size: $h1;
    line-height: var(--line-height);
    color: var(--new-front-primary-font-color);
    @media (max-width: 950px) {
      text-align: center;
    }
  }
}
</style>
