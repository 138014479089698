import Vue from 'vue';
import Vuex, {Store} from 'vuex';

import app from './modules/app';
import user from './modules/user';
import orders from './modules/orders';
import invoices from './modules/invoices';
import balances from './modules/balances';
import withdrawalRequests from './modules/withdrawal-requests';
import withdrawalRequestModal from './modules/withdrawal-request-modal';
import transactions from "./modules/transactions";
import exchanges from './modules/exchanges';
import crossChain from "./modules/crossChain";
import swap from "./modules/swap";
import dashboard from './modules/dashboard';
import rollingReserve from './modules/rolling-reserve';
import accounts from './modules/accounts';
import donations from "./modules/donations";
import payoutBalances from "./modules/payoutBalances";
import addressBook from "./modules/addressBook";
import historyAddresses from "./modules/historyAddresses";
import addressNetwork from "./modules/addressNetwork";
import organizations from "./modules/organizations";
import subscriptions from "./modules/subscriptions";
import summary from "./modules/summary";
import orphan from "@/public/store/modules/orphan";
import webhooks from "@/public/store/modules/webhooks";
import collectingProfits from "@/public/store/modules/collecting-profits";
import authorizationHistory from "@/public/store/modules/authorizationHistory";
import analytics from "@/public/store/modules/analytics";
import menu from "@/public/store/modules/menu";
import sepaSwift from "@/public/store/modules/sepaSwift";

import personalAddresses from "./modules/personal-addresses";
import organizationSettings from "./modules/organizationSettings";

Vue.use(Vuex);
Vue.config.devtools = import.meta.env.DEV
export const store = new Store({
    modules: {
        app,
        user,
        orders,
        balances,
        withdrawalRequests,
        withdrawalRequestModal,
        transactions,
        exchanges,
        crossChain,
        swap,
        dashboard,
        payoutBalances,
        rollingReserve,
        accounts,
        donations,
        invoices,
        addressBook,
        addressNetwork,
        historyAddresses,
        organizations,
        subscriptions,
        personalAddresses,
        orphan,
        summary,
        webhooks,
        collectingProfits,
        authorizationHistory,
        analytics,
        menu,
        sepaSwift,
        organizationSettings
    },
});

export default store;
