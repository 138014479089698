import getOrganizationUsersQuery from "/public/graphQl/queries/getOrganizationUsers.js";

export const getOrganizationUsers = async ({commit, dispatch, rootGetters}) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    try {
        commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getOrganizationUsersQuery,
           variables: {
               organizationId: selectedOrganizationId,
           }
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {
            getOrganizationUsers,
        },
    } = currenciesData;
    commit('setListUser', getOrganizationUsers);

    return true;
};
