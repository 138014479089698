<template>
<div>
  <LinesBackground class="linesBackground" />
  <div class="logo">
    <logo />
  </div>
  <div class="switcher">
    <NewThemeSwitch  />
  </div>
  <div class="lang-change-trigger">
    <LanguageSwitcher />
  </div>
  <div class="login__wrapper">
    <div class="progress">
      <ProgressRegistration :step="step" />
    </div>
    <div  class="login-form__wrapper">
      <h1 v-if="step === 1" class="login-form__title">{{ $t('authorization.registration-title') }}</h1>
      <form v-if="step === 1"  @submit.prevent="onSubmit" >
        <div class="login-input__wrapper">
          <label class="login-input__label" for="username">{{ $t('authorization.email') }}</label>
          <input  class="login-input" name="username" v-model="email" placeholder="E-mail" />
        </div>
        <div class="login-input__wrapper">
          <label
            class="login-input__label"
            for="password"
          >
            {{ $t('authorization.password') }}
          </label>
          <NInputPassword
            @focus="isRulesOpen = true"
            ref="passwordInput"
            v-model="password"
          />
        </div>
          <div :class="['rules', isRulesOpen ? 'rules--opened' : '']" :key="lang">
              <div v-for="rule in rules" :key="rule.rule" :class="['rules__item', rule.passed ? 'rules__item--passed' : '']">
                  <svg v-if="!rule.passed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE4040"/>
                      <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE4040"/>
                      <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE4040"/>
                  </svg>
                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.114 7.33795C16.5455 6.88735 17.245 6.88735 17.6764 7.33795C18.1026 7.78303 18.1078 8.50126 17.6921 8.95303L11.8114 16.6303C11.803 16.6414 11.7939 16.652 11.7843 16.662C11.3528 17.1127 10.6533 17.1127 10.2219 16.662L6.32358 12.5906C5.89214 12.14 5.89214 11.4094 6.32358 10.9588C6.75502 10.5082 7.45453 10.5082 7.88597 10.9588L10.9698 14.1796L16.0847 7.37247C16.0938 7.36036 16.1036 7.34884 16.114 7.33795Z" fill="#5BB24D"/>
                      <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#5BB24D"/>
                  </svg>

                  {{ $t(`authorization.${rule.title}`) }}
              </div>
          </div>
        <div class="login-input__wrapper">
          <label  class="login-input__label" for="rePassword">{{ $t('authorization.repeat-password') }}</label>
          <NInputPassword v-model="rePassword" />
        </div>
        <div class="login-input__wrapper">
          <label class="login-input__label" for="code">{{ $t('authorization.ref-code') }}</label>
          <input  class="login-input" name="code" v-model="refCode" :placeholder="$t('authorization.ref-code')" />
        </div>
        <div v-if="errorCode" class="error-banner">
          <svg class="error-banner__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.80977 0.21967C6.95042 0.0790176 7.14118 0 7.3401 0H16.6599C16.8588 0 17.0496 0.0790176 17.1902 0.21967L23.7803 6.80977C23.921 6.95042 24 7.14118 24 7.3401V16.6599C24 16.8588 23.921 17.0496 23.7803 17.1902L17.1902 23.7803C17.0496 23.921 16.8588 24 16.6599 24H7.3401C7.14118 24 6.95042 23.921 6.80977 23.7803L0.21967 17.1902C0.0790176 17.0496 0 16.8588 0 16.6599V7.3401C0 7.14118 0.0790176 6.95042 0.21967 6.80977L6.80977 0.21967ZM7.65076 1.5L1.5 7.65076V16.3492L7.65076 22.5H16.3492L22.5 16.3492V7.65076L16.3492 1.5H7.65076Z" fill="#BE4040"/>
            <path d="M10.5023 16.5C10.5023 15.6716 11.1739 15 12.0023 15C12.8307 15 13.5023 15.6716 13.5023 16.5C13.5023 17.3284 12.8307 18 12.0023 18C11.1739 18 10.5023 17.3284 10.5023 16.5Z" fill="#BE4040"/>
            <path d="M10.6493 7.49256C10.5693 6.69343 11.1969 6 12 6C12.8031 6 13.4307 6.69343 13.3507 7.49256L12.8246 12.7537C12.7823 13.1774 12.4258 13.5 12 13.5C11.5742 13.5 11.2177 13.1774 11.1754 12.7537L10.6493 7.49256Z" fill="#BE4040"/>
          </svg>
          <span class="error-banner__text">{{ errorCodesTitle }}</span>
        </div>
        <button class="login-button" type="submit">{{ $t('authorization.registration-title') }}</button>
      </form>
      <div v-if="step === 2" class="email-confirm">
        <h1 class="email-confirm__title">{{ $t('authorization.verification-email') }}</h1>
        <p class="email-confirm__description">
          {{ $t('authorization.email-description', {
          email: confirmationEmail
        }) }}.
        </p>
      </div>
      <div class="login-description">
        {{ $t('authorization.have-acc') }} <RouterLink class="login-description__link" :to="`/login?${utm}`">{{ $t('authorization.login-title') }}</RouterLink>
      </div>
    </div>
  </div>
</div>


</template>

<script>
    import axios from "axios";
    import LinesBackground from "@/public/components/elements/LinesBackground";
    import {mapActions, mapMutations, mapState} from "vuex";
    import NewThemeSwitch from "@/public/components/elements/NewThemeSwitch";
    import ProgressRegistration from "@/public/components/elements/registration/ProgressRegistration";
    import NInputPassword from "@/public/components/common/NInputPassword";
    import ChangeLanguageModal from "@/public/components/modals/ChangeLanguageModal";
    import LanguageSwitcher from "@/public/components/common/LanguageSwitcher";
    import {lkLogoLoader} from "@/public/utils/logo-loader";

    export default {
    name: "login",
    components: {
      LanguageSwitcher,
      ChangeLanguageModal, NInputPassword, ProgressRegistration, NewThemeSwitch, LinesBackground, Logo: lkLogoLoader()},
    data() {
      return {
        email: '',
        password: '',
        rePassword: '',
        refCode: '',
          isRulesOpen: false,

          confirmationEmail: import.meta.env.VITE_EMAIL_CONFIRMATION,

        pending: false,
        errorCode: 0,
        step: 1,
          rules: [
              {
                  rule: 'length',
                  title: 'minimum-characters',
                  passed: false
              },
              {
                  rule: 'case',
                  title: 'uppercase-restriction',
                  passed: false
              },
              {
                  rule: 'specChar',
                  title: 'spec-char',
                  passed: false
              },
              {
                  rule: 'number',
                  title: 'number-char',
                  passed: false
              },
          ],
      };
    },
    created() {
      if (!this.isUserLoggedIn) return

      this.$router.push({name: 'dashboard'});
    },
      mounted() {
        this.refCode = this.$route.query.refCode
      },
      computed: {
      ...mapState('app', ['authorization']),
          ...mapState('user', ['lang']),
      isUserLoggedIn () {
        return this.authorization.login
      },
      errorCodesTitle() {
        let title = ''
        switch (this.errorCode) {
          case 3000:{
           title = this.$t('authorization.invalid-email')
          }break;
          case 3001:{
            title = this.$t('authorization.invalid-password')
          }break;
          case 3002:{
           title = this.$t('authorization.invalid-password-not-match')
          }break;
          case 4000:{
            title = this.$t('authorization.user-existing')
          }break;
          default:
            alert('Internal error, code: ' + data.code);
            break;
        }
        return title
      },
      utm () {
        const utm = document.location.search;
        return !!utm ? utm.split('?').join('') : ''
      }
    },
      watch: {
        password: {
            handler (value) {
                this.rules[0].passed = value.length >= 6

                const caseRegexp = /^(?=.*[a-z])(?=.*[A-Z]).*$/g
                this.rules[1].passed = value.match(caseRegexp)

                const specCharRegexp = /\W+/g
                this.rules[2].passed = value.match(specCharRegexp)

                const numberRegexp = /\d/g
                this.rules[3].passed = value.match(numberRegexp)
            }
        },
      },
    methods: {
      ...mapMutations('app', ['setAuthLogin', 'setAuthTwoFa']),
      ...mapActions('app', ['init']),
      async onSubmit() {
        this.pending = true;

        if (!!this.utm) {
          localStorage.setItem('utm', this.utm);
        }
        const isPasswordCorrect = this.rules.every(({passed}) => passed)
          if (!isPasswordCorrect) return

        try {
          const {data} = await axios.post(`/public-api/sign-up${ document.location.search }`, {
            email: this.email,
            password: this.password,
            rePassword: this.rePassword,
            utm: this.utm, // todo: сами выцепите как оно берется со станици реги в осп
            refCode: this.refCode, // todo: сами посмотрите от куда он берется там же
          });

          if(!data.success) {
            /*
            INCORRECT_EMAIL: 3000,
            INCORRECT_PASSWORD: 3001,
            INCORRECT_RE_PASSWORD: 3002,
            ALREADY_EXISTS: 4000,
            * */
            this.errorCode = data.code
            this.pending = false;
            return
          }

          this.success = true;
          this.step = 2
          this.pending = false;
        } catch (e) {
          await new Promise(r => setTimeout(r, 1000));

          return this.onSubmit();
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
* {
  transition: all .2s ease;
}
.rules {
  font-family: $Gilroy;
  padding-left: 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;
  &--opened {
    max-height: 150px;
    margin-bottom: 20px;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--new-front-text-error-bg-modal-primary);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    &--passed {
      color: var(--new-front-text-success-bg-primary);
    }
  }
}
.login {
  &__wrapper {
    font-family: 'Gilroy';
    background: var(--new-front-gradient-bg);
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &-form {
    &__title {
      font-size: 36px;
      margin-bottom: 50px;
      font-weight: 500;
      color: var(--new-front-primary-font-color);
    }
    &__wrapper {
      z-index: 2;
      padding: 30px;
      background: var(--new-front-main-bg);
      border-radius: 30px;
      max-width: 640px;
      width: 100%;
    }
  }
  &-input {
    &__label {
      color: var(--new-front-primary-label-color);
      font-size: 18px;
      margin-bottom: 15px;
    }
    &__wrapper {
      margin-bottom: 20px;
    }
  }
  &-button {
    font-weight: 500;
    font-size: 18px;
    background: #744DB2;
    border-radius: 10px;
    line-height: 28px;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    outline: none;
    border: none;
    margin-top: 30px;
    cursor: pointer;
  }
  &-description {
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: var(--new-front-primary-font-color);
    &__link {
      color:#6750A4;
    }
  }
}
.login-input {
  width: 100%;
  border-radius: 9px;
  outline: none;
  padding: 15px;
  border: 1px var(--new-front-input-border) solid;
  font-size: 18px;
  margin-top: 10px;
  background: var(--new-front-input-bg);
  color: var(--new-front-primary-font-color);
}
.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}
.linesBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--new-front-gradient-bg) !important;
}
.switcher {
  position: absolute;
  top: 30px;
  right: 30px;
}
.error-banner {
  margin-left: 12px;
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 20px;
  }
  &__text {
    color: #BE4040;
    font-weight: 500;
    font-size: 16px;
  }
}
.email-confirm {
  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    color: var(--new-front-primary-font-color);
    margin-bottom: 30px;
  }
  &__description {
    text-align: center;
    color: var(--new-front-secondary-font-color);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
}
.lang-change-trigger {
  position: absolute;
  right: 132px;
  top: 38px;
}
@media (max-width: 556px) {
  .login {
    &-description {
      font-size: 13px;
    }
    &__wrapper {
      padding: 10px;
    }
    &-form {
      &__wrapper {
        padding: 20px;
        border: 1px solid var(--new-front-border-color);
      }
      &__title {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
  }
  .login-input {
    font-size: 13px;
    &__label {
      font-size: 13px;
    }
  }
  .error-banner {
    margin-left: 12px;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 15px;
    }
    &__text {
      color: #BE4040;
      font-weight: 500;
      font-size: 13px;
    }
  }
  .logo {
    left: 10px;
    top: 10px;
  }
  .switcher {
    right: 10px;
    top: 10px;
  }
  .lang-change-trigger {
    position: absolute;
    right: 105px;
    top: 17px;
  }
}
</style>
