<template>
<div :class="['base-input__wrapper', disabled ? 'disabled' : '']">
    <div
            v-if="label || error"
            class="label-block"
    >
        <div class="label">
            {{ label }}
        </div>
        <div class="error">
            {{ error }}
        </div>
    </div>
    <textarea
            v-model="valueModel"
            class="search-input"
            :class="{ 'error': !!error }"
            :placeholder="placeholder"
            :type="type"
            v-bind="$attrs"
    />
    <div class="subItem-block">
        <div
                v-if="error"
                class="error"
        >
            {{ error }}
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "BaseTextarea",
        props: {
            label: {
                type: String,
                default: ''
            },
            value: {
                type: [Number, String],
                default: undefined
            },
            placeholder: {
                type: String,
                default: ''
            },
            maxLength: {
                type: Number,
                default: undefined
            },
            minLength: {
                type: Number,
                default: undefined
            },
            type: {
                type: String,
                default: 'text'
            },
            error: {
                type: String,
                default: undefined
            },
          disabled: {
            type: Boolean,
            default: false
          },
        },
        computed: {
            valueModel: {
                get({ value }) {
                    return value || ''
                },
                set(v) {
                    this.$emit('input', undefined)

                    this.$nextTick(() => {
                        if (!!this.maxLength) {
                            this.$emit('input', v.slice(0, this.maxLength))
                        } else {
                            this.$emit('input', v)
                        }
                    })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.disabled {
  opacity: .6;
  pointer-events: none;
}
.base-input {
  &__wrapper {
    width: 100%;
    position: relative;
    .label-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $Gilroy;
      font-size: var(--new-front-input-label-font-size);
      margin-bottom: 5px;

      .label {
        font-size: $h6;
        color: var(--new-front-input-label-font-color);
      }
      .error {
        font-size: $h6;
        color: var(--new-front-input-font-color-error);
      }
    }
    .subItem-block {
      font-size: var(--new-front-input-label-font-size);

      .error {
        display: none;
        margin-top: 5px;
        color: var(--new-front-input-font-color-error);
      }
    }


    textarea {
      font-family: $Gilroy;
      width: 100%;

      background-color: transparent;
      border: 1px solid var(--new-front-input-border);
      border-radius: 10px;

      color: var(--new-front-input-font-color);
      font-size: $h4;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      box-sizing: border-box;
      padding: 15px 20px;
      height: 144px;
      resize: none;
      font-weight: 500;
      &::placeholder {
        color: var(--new-front-secondary-font-color);
      }
      &:focus, textarea:focus, select:focus {
        outline: none !important;
      }
      &.error {
        border: 1px solid var(--new-front-input-border-error);
      }
    }

    //Remove Arrows/Spinners
    textarea::-webkit-outer-spin-button,
    textarea::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    textarea[type=number] {
      -moz-appearance: textfield;
    }

    @include below_phone() {
      textarea {
        &::placeholder {
          font-size: $h6;
        }
      }
      .label-block {
        .label {
          font-size: $h8;
        }
        .error {
          display: none;
          font-size: $h8;
        }
      }

      .subItem-block {
        .error {
          display: block;
          font-size: $h8;
        }
      }
    }
  }
}
</style>
