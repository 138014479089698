import updateOrganizationRoleMutation from "/public/graphQl/mutations/updateOrganizationRole.js";

export const updateOrganizationRole = async ({commit, dispatch, rootGetters, state}, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    let variables = {
        organizationId: selectedOrganizationId,
        organizationRoleId: state.detailRoleId,
        name: payload.name,
        rules: JSON.stringify(payload.rules),
        comment: payload.comment,
    };

    try {
        commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: updateOrganizationRoleMutation,
            variables,
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    return true;
};