<template>
<header :class="['header', isAdvanceBalance ? 'advanceBalancePage' : '']">
  <div class="header__left">
    <div v-if="title" class="header__title">
      <div class="block">{{ $t(title.title) }}</div>
      <div class="block">
        <common-info-modal
          v-if="title.description"
          :description="$t(title.description)"
        />
      </div>
      <div class="block">
        <video-info-modal
          v-if="videoLink"
          :source="videoLink"
        />
      </div>
    </div>
    <p v-if="title" class="header__subtitle">
      {{ $t(title.subtitle) }} <br>
    </p>
  </div>
  <div class="header__right">
    <HeaderSummary v-if="isAnalyticsAllowed" class="header-summary" :class="{'with-two-fa-banner': !userTwoFactorAuth && !isCreateDonationRoute}" />
    <HeaderAdvBalance />
<!--    <div style="display: flex; align-items: center; flex-wrap: nowrap">-->
<!--      <HeaderSettings @openLanguage="openLanguagesModal" />-->
<!--      <HeaderBurger />-->
<!--    </div>-->
    <language-switcher :is-trigger-required="false"/>
    <header-theme />
    <header-language />
    <div class="divider"/>
    <base-button
      style="width: 48px"
      type="secondary"
      class="button-logout"
      @click="logOut"
    >
      <template #prepend>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_20537_59555)">
            <path d="M9.9668 18.3057H2.49168C2.0332 18.3057 1.66113 17.9336 1.66113 17.4751V2.52492C1.66113 2.06645 2.03324 1.69438 2.49168 1.69438H9.9668C10.4261 1.69438 10.7973 1.32313 10.7973 0.863828C10.7973 0.404531 10.4261 0.0332031 9.9668 0.0332031H2.49168C1.11793 0.0332031 0 1.15117 0 2.52492V17.4751C0 18.8488 1.11793 19.9668 2.49168 19.9668H9.9668C10.4261 19.9668 10.7973 19.5955 10.7973 19.1362C10.7973 18.6769 10.4261 18.3057 9.9668 18.3057Z" :fill="theme === 'dark' ? '#FFFFFF' : '#744DB2'"/>
            <path d="M19.7525 9.40855L14.7027 4.42515C14.3771 4.10288 13.8505 4.10706 13.5282 4.43347C13.206 4.75988 13.2093 5.28562 13.5366 5.60788L17.1454 9.16933H7.47508C7.01578 9.16933 6.64453 9.54058 6.64453 9.99988C6.64453 10.4592 7.01578 10.8305 7.47508 10.8305H17.1454L13.5366 14.3919C13.2093 14.7142 13.2068 15.2399 13.5282 15.5663C13.6055 15.6447 13.6976 15.707 13.7991 15.7494C13.9006 15.7919 14.0096 15.8138 14.1196 15.8138C14.3306 15.8138 14.5415 15.7341 14.7027 15.5746L19.7525 10.5912C19.8308 10.5139 19.893 10.4218 19.9355 10.3203C19.978 10.2188 19.9999 10.1099 20 9.99984C20 9.77734 19.9111 9.56554 19.7525 9.40855Z" :fill="theme === 'dark' ? '#FFFFFF' : '#744DB2'"/>
          </g>
          <defs>
            <clipPath id="clip0_20537_59555">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </template>
    </base-button>
    <base-button
        style="width: 48px"
        type="secondary"
        class="button-menu"
        @click="toggleNav"
    >
      <template #prepend>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="15" y="15" width="18" height="3" rx="1" :fill="theme === 'dark' ? '#FFFFFF' : '#6750A4'"/>
          <rect x="15" y="22" width="18" height="3" rx="1" :fill="theme === 'dark' ? '#FFFFFF' : '#6750A4'"/>
          <rect x="15" y="29" width="18" height="3" rx="1" :fill="theme === 'dark' ? '#FFFFFF' : '#6750A4'"/>
        </svg>
      </template>
    </base-button>
  </div>
</header>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

  import HeaderMerchantArea from "./components/HeaderMerchantArea.vue";
  import PrivateSwitchTheme from "../../elements/PrivateSwitchTheme.vue";
  import HeaderAdvBalance from "../../elements/header/HeaderAdvBalance";
  import HeaderSettings from "../../elements/header/HeaderSettings";
  import LanguageSwitcher from "../../common/LanguageSwitcher";
  import HeaderBurger from "../../elements/header/HeaderBurger";
  import HeaderSummary from "../../elements/summary/header";
  import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";
  import VideoInfoModal from "@/public/components/modals/video/index.vue";
  import BaseButton from "@/public/components/common/uiKit/button/base/bg-secondary/index.vue";
  import HeaderLanguage from "@/public/components/general/header/components/HeaderLanguageNew";
  import HeaderTheme from "@/public/components/general/header/components/HeaderThemeNew";

  const titles = {
    // Route name
    'dashboard': {
        // Main title
        title: 'navigation.main',
        // Subtitle
        subtitle: null,
        // Info modal
        description: null,
    },
    'wallets': {
        title: 'navigation.addresses_sub',
        subtitle: 'pages-description.pay-in-description',
        description: 'info_modals.pay_in.description',
    },
    'invoices': {
        title: 'navigation.invoice',
        subtitle: 'pages-description.invoices-description',
        description: 'info_modals.bills.description',
    },
    'orders': {
        title: 'navigation.orders',
        subtitle: 'pages-description.orders-description',
        description: 'info_modals.orders.description',
    },
    'orphan': {
        title: 'navigation.orphan',
        subtitle: 'orphan.subtitle',
        description: 'info_modals.orphan.description',
    },
    'personal-addresses': {
        title: 'personal-addresses.title',
        subtitle: 'personal-addresses.subtitle',
        description: 'info_modals.personal_addresses.description',
    },
    'businessWallet': {
        title: 'business-wallet.title',
        subtitle: 'pages-description.business-wallet-description',
        description: 'info_modals.business_wallet.description',
    },
    'bridge': {
        title: 'bridge.title',
        subtitle: 'pages-description.crosschain-description',
        description: 'info_modals.bridge.description',
    },
    'bridgeHistory': {
      title: 'bridge.history.title',
      subtitle: null
    },
    'swap': {
        title: 'swap.title',
        subtitle: 'swap.subtitle',
        description: 'info_modals.swap.description',
    },
    'swapHistory': {
      title: 'swap.history.title',
      subtitle: null
    },
    'subscriptions': {
        title: 'subscriptions.title',
        subtitle: 'subscriptions.second-description',
        description: 'info_modals.subscriptions.description',
    },
    'recurrentsAddress': {
        title: 'subscriptions-addresses.title',
        subtitle: 'subscriptions-addresses.description',
        description: null,
    },
    'payout-wallet': {
        title: 'payout-wallet.title',
        subtitle: 'payout-wallet.subtitle',
        description: 'info_modals.transactions.description',
    },
    'payout-wallet-balance': {
        title: 'payout-wallet.title',
        subtitle: 'payout-wallet.subtitle',
        description: 'info_modals.transactions.description',
    },
    'addressBook': {
        title: 'address_book.title',
        subtitle: 'pages-description.address-book-description',
        description: 'info_modals.address_book.description',
    },
    'transactions': {
        title: 'transactions.title',
        subtitle: 'pages-description.transactions-description',
        description: 'info_modals.transactions.description',
    },
    'collectingProfits': {
        title: 'profits.title',
        subtitle: 'profits.subtitle',
        description: 'info_modals.profits.description',
    },
    'collectingHistory': {
        title: 'profits.history-title',
        subtitle: null,
        description: null,
    },
    'currentHistoryAddress': {
        title: 'historyAddresses.title_main_current',
        subtitle: null,
        description: null,
    },
    'historyAddressesBusiness': {
        title: 'historyAddresses.title_main',
        subtitle: null,
        description: null,
    },
    'analytics': {
        title: 'analytics.title',
        subtitle: null,
        description: null,
    },
    'withdrawal-fees': {
        title: 'information.title',
        subtitle: 'information.description',
        description: null,
    },
    'referral': {
        title: 'referral.title',
        subtitle: 'referral.description',
        description: 'info_modals.referral.description',
    },
    'apiKeys': {
        title: 'api_keys.title',
        subtitle: 'api_keys.description',
        description: null,
    },
    'apiKeysCreate': {
        title: 'api_keys.title',
        subtitle: 'api_keys.description',
        description: null,
    },
    'webhooks': {
        title: 'webhooks.title',
        subtitle: 'webhooks.subtitle',
        description: null,
    },
    'webhook-info': {
        title: 'webhook-info.title',
        subtitle: null,
        description: null,
    },
    'organizations': {
        title: 'organization.organizations_title',
        subtitle: 'organization.organizations_description',
        description: 'info_modals.organization.description',
    },
    'advanced-balances': {
        title: '',
        subtitle: null,
        description: null,
    },
    'bank-invoices': {
      title: 'navigation.accounts',
      subtitle: null,
      description: null,
    },
    'sepa-swift': {
      title: 'Sepa/Swift',
      subtitle: null,
      description: null,
    },
    'integrations': {
      title: 'navigation.integrations',
      subtitle: null,
      description: null,
    },
    'payments': {
      title: 'navigation.payments',
      subtitle: null,
      description: null,
    },
    'advanced-balances-commission': {
        title: 'advance_balances.commissions.banner.title',
        subtitle: 'advance_balances.description',
        description: null,
    },
    'integration-settings': {
        title: 'integration-settings.title',
        description: null,
    }
  }

  export default {
    name: 'VHeader',
    components: {
      BaseButton,
      CommonInfoModal,
      HeaderBurger,
      LanguageSwitcher,
      HeaderSettings,
      HeaderAdvBalance,
      PrivateSwitchTheme,
      HeaderMerchantArea,
      HeaderLanguage,
      HeaderSummary,
      VideoInfoModal,
      HeaderTheme
    },
    computed: {
      ...mapState('app', [
        'theme',
      ]),
      ...mapState('user', {
        userOtpEnabled: state => state.user.otpToken,
      }),
      ...mapGetters('organizations', ['userRoleRules']),
      title () {
        return titles[this.$route.name]
      },
      isAdvanceBalance () {
        return this.$route.name === 'advanced-balances'
      },
      isAnalyticsAllowed() {
        if (!this.userRoleRules['analytics']) {
          return
        }
        return this.userRoleRules['analytics']?.entities?.view_analytics || this.userRoleRules['analytics'].isAllowed;
      },
      userTwoFactorAuth() {
        return !!this.userOtpEnabled;
      },
      isCreateDonationRoute() {
        return this.$route && (this.$route.name === 'create-donation' || this.$route.name === 'edit-donation');
      },
      videoLink({ $route: { name: routeName } }) {
        const { host } = location
        const isApollopayment = host.includes('apollo')

        if (isApollopayment) return

        switch (routeName) {
          case 'invoices':
            return 'https://www.youtube.com/embed/VpBLuIlYjfk?si=bsbO9PuPAAEiokqA'
          case "orders":
            return 'https://www.youtube.com/embed/i9BMG_NQBeU?si=N2v8s07BIR9Ds1Px'
          case "subscriptions":
            return 'https://www.youtube.com/embed/Olq3bQNwANI?si=1bF4J5EAHtFmn4e3'
          default:
            return false
        }
      }
    },
    methods: {
      ...mapActions('app',
          ['openModal'],
      ),
      ...mapMutations('app', ['toggleNavState']),
      openLanguagesModal() {
        const {openModal} = this;

        openModal({
          name: 'LanguagesModal',
        });
      },
      toggleNav () {
        this.toggleNavState()
      },
      logOut() {
        const logout = window.open('/logout', '_self')
        if (logout) {
          setTimeout(() => {
            window.open('/merchant/login', '_self')
          }, 250)
        }
      }
    }
  };

</script>

<style lang="scss" scoped>

.header {
  width: 100%;
  //background: var(--primary-color);
  padding: 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  z-index: 6;
  @media (max-width: 1180px) {
    z-index: 5;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: end;

    gap: 10px;

    @media (max-width: 855px) {
      flex-wrap: wrap;
    }

    @media (max-width: 576px) {
      justify-content: flex-end;
      margin-bottom: 10px;
      flex-wrap: nowrap;
    }

    .divider {
      height: 48px;
      width: 1px;
      background-color: var(--new-front-divider-color);
    }
  }

  .header-summary {
    margin-right: 10px;
  }
  &__left {
    max-width: 40%;
  }
  &__title {
    font-family: $Gilroy;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    line-height: 31px;
    margin-bottom: 5px;

    > svg {
      margin-left: 5px;
    }
    @media (max-width: 576px) {
      font-size: 20px;
      min-width: 300px;
    }
  }

  &__subtitle {
    font-family: $Gilroy;
    font-size: $h3;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    @media (max-width: 576px) {
      font-size: 13px;
      min-width: 300px;
    }
  }

  &_content {
    background: var(--primary-color);
    width: 100%;
    border: none;
  }

  &__language {
    width: 80px;
    margin-right: 30px;
  }

  &__container {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    min-height: 106px;
    display: flex;
    gap: 32px;
    justify-content: flex-end;

    &_span {
      min-height: 0;

    }
  }

  &.row {
    justify-content: space-between;
    align-items: center;
  }

  .block {
    display: inline-block;

    &:first-child {
      display: inline;
    }

    &:not(:first-child) {
      position: relative;
      bottom: 2px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.button-menu {
  display: none;
}
@media screen and (max-width: 1180px) {
  .divider {
    display: none;
  }
  .button-menu {
    display: block;
  }
  .button-logout {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .header {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: unset;
    padding: 10px 10px 10px 10px;

    .header-summary {
      position: absolute;
      top: 77px;
      right: 10px;

      &.with-two-fa-banner {
        //top: 143px;
      }
    }
  }
}
.advanceBalancePage {
  width: fit-content;
  align-self: end;
}
</style>
