export const mutations = {
    isInit(state, status) {
        state.isInit = status;
    },
    toggleNavState (state) {
      state.isNavOpen = !state.isNavOpen
    },
    setNavState (state, payload) {
        state.isNavOpen = payload
    },
    currencies(state, currencies) {
        const networks = {};

        state.currencies = currencies
            .map(currency => {
                currency.networks = currency.networks
                    .map(network => {
                        network = Object.freeze(network);

                        networks[network.id] = Object.freeze({
                            id: network.id,
                            name: network.name,
                            alias: network.alias,
                            confirmations: network.confirmations,
                            addressRegex: network.addressRegex,
                            tagRegex: network.tagRegex,
                            underMaintenance: network.underMaintenance,

                            networkFee: networks[network.id] && networks[network.id].networkFee ? networks[network.id].networkFee : (network.isDefault ? network.networkFee : 0),
                            networkFeeUSD: networks[network.id] && networks[network.id].networkFeeUSD ? networks[network.id].networkFeeUSD : (network.isDefault ? network.networkFeeUSD : 0),

                            minWithdrawal: networks[network.id] && networks[network.id].minWithdrawal ? networks[network.id].minWithdrawal : (network.isDefault ? network.minWithdrawal : 0),
                        });

                        return network;
                    });

                return Object.freeze(currency);
            });

        state.networks = Object.values(networks);
    },
    organizations(state, organizations) {
        state.organizations = organizations;
    },
    selectOrganization(state, organizationId) {
        state.selectedOrganizationId = organizationId;
    },
    openedModal(state, modalName) {
        state.modal.opened = modalName;
    },
    openedModalPayload(state, payload) {
        state.modal.payload = payload;
    },
    toggleLoading(state) {
        state.isLoading = !state.isLoading;
    },
    enableLoading(state) {
        state.isLoading = true;
    },
    disableLoading(state) {
        state.isLoading = false;
    },
    setTheme(state, theme) {
        state.theme = theme;
    },

    setAuthLogin(state, login) {
        if(state.authorization.login === login) {
            return;
        }

        state.authorization.login = login;

        if(!login) {
            state.authorization.twoFa = false;
        }
    },
    setAuthTwoFa(state, twoFa) {
        if(state.authorization.twoFa === twoFa) {
            return;
        }

        state.authorization.twoFa = twoFa;
    },
};
