<template>
<div class="webhooks">
<!--    <h1 class="webhooks__title">-->
<!--        {{ $t('webhooks.title') }}-->
<!--        &lt;!&ndash;        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--        &lt;!&ndash;            <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#8B81A0"/>&ndash;&gt;-->
<!--        &lt;!&ndash;            <path d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z" fill="#8B81A0"/>&ndash;&gt;-->
<!--        &lt;!&ndash;            <path d="M9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z" fill="#8B81A0"/>&ndash;&gt;-->
<!--        &lt;!&ndash;        </svg>&ndash;&gt;-->
<!--&lt;!&ndash;        <common-info-modal&ndash;&gt;-->
<!--&lt;!&ndash;                :description="$t('info_modals.orphan.description')"&ndash;&gt;-->
<!--&lt;!&ndash;        />&ndash;&gt;-->

<!--    </h1>-->
<!--    <h3 class="webhooks__subtitle">-->
<!--        {{ $t('webhooks.subtitle') }}-->
<!--    </h3>-->
    <zero-state
        v-if="showZeroState"
        :meta="zeroStateMeta"
        :background-transparent="false"
    />
    <Container v-else >
        <div class="webhooks__topBar">
            <div class="webhooks__searchRow">
                <div class="search">
                    <orphan-search :placeholder="$t('webhooks.search')" v-model="searchString" />
                </div>
                <refresh-button  @click="refresh"/>
            </div>
            <div class="webhooks__gridRow">
                <webhooks-filter-select
                    :value="responseCode"
                    :list="statuses"
                    :label="$t('business-wallet.currency')"
                    :placeholder="$t('webhooks.response-status')"
                    uniq-key="value"
                    clearable
                    @input="handleSelectResponseCode"
                />
                <webhooks-filter-select
                    :value="responseType"
                    :list="events"
                    :label="$t('orders.network')"
                    :placeholder="`${$t('webhooks.event')}...`"
                    clearable
                    uniq-key="value"
                    @input="handleSelectResponseType"
                />
                <new-datepicker :placeholder="$t('webhooks.period')" @input="handleSelectDates"/>
<!--                <orphan-status-select-->
<!--                        :value="statusValue"-->
<!--                        :list="statuses"-->
<!--                        :label="$t('orphan.select-status')"-->
<!--                        :placeholder="$t('webhooks.period')"-->
<!--                        uniq-key="value"-->
<!--                        @input="handleSelectStatus"-->
<!--                />-->
            </div>
        </div>
        <div class="webhooks__topBar--mobile">
            <refresh-button @click.native="refresh"/>
            <filter-mobile
              :is-filtered="!isEmptyFilters"
              :close-outside="false"
              @confirm="loadWebhooks"
              @reset="resetFilters"
            >
                <div class="webhooks__topBar-filterMobile">
                    <orphan-search :placeholder="$t('webhooks.search')" v-model="searchString" />
                    <webhooks-filter-select
                        :value="responseCode"
                        :list="statuses"
                        :label="$t('business-wallet.currency')"
                        :placeholder="$t('webhooks.response-status')"
                        uniq-key="value"
                        clearable
                        @input="handleSelectResponseCode"
                    />
                    <webhooks-filter-select
                        :value="responseType"
                        :list="events"
                        :label="$t('orders.network')"
                        :placeholder="`${$t('webhooks.event')}...`"
                        uniq-key="value"
                        clearable
                        @input="handleSelectResponseType"
                    />
                    <div class="mobile-datepicker">
                      <new-datepicker
                        :placeholder="$t('webhooks.period')"
                        @input="handleSelectDates"
                        @clear-dates="clearDates = $event"
                      />
                    </div>
                </div>
            </filter-mobile>
        </div>

            <Table class="webhooks__table" @sort="sortTable" :header-list="tableHeader">
              <template v-if="!isLoading">
                <table-item v-for="item in webhooksList" :item="item"/>
              </template>
            </Table>
            <div class="webhooks__mobileList" v-if="!isLoading">
                <webhook-mobile-item v-for="item in webhooksList" :item="item" />
            </div>
            <div v-if="!webhooksList.length" class="webhooks__table-empty">
                {{ $t('orphan.empty') }}
            </div>
            <Pagination  :current="page" :total="webhooksTotal" :page-size="pageSize" @page-change="catchPagination" v-if="webhooksTotal > this.pageSize && !isLoading" />

        <template v-if="isLoading">
            <new-spinner />
        </template>
    </Container>
</div>
</template>
<script>
    import Container from "@/public/components/common/uiKit/Container.vue";
    import Pagination from "@/public/components/common/uiKit/Pagination.vue";
    import OrphanSearch from "@/public/components/elements/orphan-transactions/OrphanSearch.vue";
    import BusinessWalletNetworkSelect
        from "@/public/components/elements/business-walet/Select/businessWalletNetworkSelect.vue";
    import Table from "@/public/components/common/uiKit/table/Table.vue";
    import RefreshButton from "@/public/components/common/uiKit/button/refresh/index.vue";
    import TableItem from "@/public/components/elements/webhooks/TableItem.vue";
    import FilterMobile from "@/public/components/elements/orphan-transactions/filter/index.vue";
    import Copy from "@/public/components/common/copy.vue";
    import OrphanMobileItem from "@/public/components/elements/orphan-transactions/OrphanMobileItem.vue";
    import {mapActions, mapGetters, mapState} from "vuex";
    import { isEmpty } from "lodash";
    import OrphanStatusSelect from "@/public/components/elements/orphan/Select/orphanStatusSelect.vue";
    import CommonInfoModal from "@/public/components/modals/commonInfo/index.vue";
    import WebhooksFilterSelect from "@/public/components/elements/webhooks/Select/WebhooksFilterSelect.vue";
    import NewDatepicker from "@/public/components/common/uiKit/Datepicker/Datepicker.vue";
    import WebhookMobileItem from "@/public/components/elements/webhooks/MobileItem.vue";
    export default  {
        name: 'Webhooks',
        rulesAlias: 'webhooks',
        components: {
            WebhookMobileItem,
            NewDatepicker,
            WebhooksFilterSelect,
            CommonInfoModal,
            OrphanStatusSelect,
            OrphanMobileItem,
            Copy,
            FilterMobile,
            NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue"),
            TableItem,
            RefreshButton,
            Table,
            BusinessWalletNetworkSelect,
            OrphanSearch,
            Container,
            Pagination,
            ZeroState: () => import(/* webpackChunkName: "public/pages/zeroState" */ '/public/pages/zeroState')
        },
        data () {
            return {
                searchString: '',
                responseType: null,
                responseCode: null,
                statusValue: null,
                sortValue: null,
                page: 1,
                pageSize: 10,
                dates: [],
                clearDates: () => {}
            }
        },
        computed: {
            ...mapState('webhooks', ['webhooksList', 'webhooksTotal']),
            ...mapState('app', ['networks', 'currencies', 'isLoading']),
            ...mapGetters('organizations', ['userRoleRules']),
            statuses () {
                return [
                    {
                        label: `2xx`,
                        subLabel: this.$t('webhooks.success'),
                        value: 200,
                        status: 'success'
                    },
                    {
                        label: `3xx`,
                        subLabel: this.$t('webhooks.redirect'),
                        value: 300,
                        status: 'error'
                    },
                    {
                        label: `4xx`,
                        subLabel: this.$t('webhooks.client-error'),
                        value: 400,
                        status: 'error',
                    },
                    {
                        label: `5xx`,
                        subLabel: this.$t('webhooks.server-error'),
                        value: 500,
                        status: 'error'
                    },
                    {
                        label: '-X',
                        subLabel: this.$t('webhooks.internal-error'),
                        value: -1,
                        status: 'error',
                    },
                ]
            },
            currencySelectPlaceholder () {
                return this.$t('orders.select_currencies')
            },
            networkSelectPlaceholder () {
                return this.$t('orders.select_network')
            },
            // paginateSortedOrphans() {
            //     let arr = this.webhooksList
            //     if (this.sortValue) {
            //         arr = this.sortValue.direction ? arr.toSorted((a, b) =>  Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))) : arr.toSorted((a, b) =>  Number(new Date(b.createdAt)) -  Number(new Date(a.createdAt)))
            //     }
            //
            //     return arr.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
            // },
            offset () {
                return (this.page - 1) * this.pageSize
            },
            tableHeader () {
                return [
                    {
                        title: this.$t('webhooks.status'),
                        slug: 'status',
                        isSort: false
                    },
                    {
                        title: 'ID',
                        slug: 'id',
                        isSort: false
                    },
                    {
                        title: this.$t('webhooks.date'),
                        slug: 'date',
                        isSort: true
                    },
                    {
                        title: this.$t('webhooks.event-date'),
                        slug: 'eventDate',
                        isSort: false
                    },
                    {
                        title: 'URL',
                        slug: 'url',
                        isSort: false
                    },
                    {
                        title: this.$t('webhooks.event'),
                        slug: 'event',
                        isSort: false
                    }
                ]
            },

            events () {
               return [
                    {
                        value: 'ORDER',
                        label: this.$t('webhooks.order')
                    },
                    {
                        value: 'INVOICE',
                        label: this.$t('webhooks.invoice')
                    },
                    {
                        value: 'WITHDRAWAL',
                        label: this.$t('webhooks.withdrawal')
                    },
                    {
                        value: 'DEPOSIT_PERSONAL',
                        label: this.$t('webhooks.deposit_personal')
                    },
                    {
                        value: 'DEPOSIT_ORPHAN',
                        label: this.$t('webhooks.deposit_orphan')
                    },
                    {
                        value: 'CROSSCHAIN_TRANSFER',
                        label: this.$t('webhooks.crosschain_transfer')
                    },
                    {
                        value: 'CROSSCHAIN_SWAP',
                        label: this.$t('webhooks.crosschain_swap')
                    },
                    {
                        value: 'REC_BILLING_LINK',
                        label: this.$t('webhooks.rec_billing_link')
                    },
                    {
                        value: 'REC_SUBSCRIPTION',
                        label: this.$t('webhooks.rec_subscription')
                    },
                    {
                        value: 'REC_FREE_PAYMENT',
                        label: this.$t('webhooks.rec_free_payment')
                    },
                    {
                       value: 'AUTO_SWAP',
                       label: this.$t('webhooks.auto_swap')
                    }
                ]
            },
            showZeroState({ isEmptyFilters, webhooksList }) {
              return isEmptyFilters && !webhooksList.length
            },
            isEmptyFilters({ searchString, responseCode, responseType, dates }) {
              return isEmpty(searchString) && isEmpty(responseCode) && isEmpty(responseType) && isEmpty(dates)
            },
            zeroStateMeta() {
              return {
                title: this.$t('zero-states.webhooks.title'),
                subtitle: this.$t('zero-states.webhooks.subtitle'),
                notifications: [
                  this.$t('zero-states.webhooks.notifications.first-line')
                ],
                actions: [
                  {
                    title: this.$t('zero-states.webhooks.actions.api-integration.title'),
                    info: this.$t('zero-states.webhooks.actions.api-integration.info'),
                    btnName: this.$t('zero-states.webhooks.actions.api-integration.btn-name'),
                    btnSecondary: true,
                    withoutIcon: true,
                    apiMethod: '/webhooks'
                  }
                ],
                type: 'default'
              }
            }
        },
        created() {
            const isAllowed = this.userRoleRules[this.$options.rulesAlias].isAllowed;
            if (!isAllowed) {
                this.$Message.error(this.$t('rules.not_allowed'));
                this.$router.push('/merchant/dashboard');
            }
            this.loadWebhooks()
        },
        watch: {
            searchString () {
                this.loadWebhooks()
            }
        },
        methods: {
            ...mapActions('webhooks', ['getWebhooks']),
            async loadWebhooks () {
                const variables = {
                    date: this.dates,
                    webhookStatus: this.responseCode ? this.responseCode.value : null,
                    event: this.responseType ? this.responseType.value : null,
                    query: this.searchString,
                    limit: this.pageSize,
                    offset: this.offset,
                    sortDate: this.sortValue
                }
                await this.getWebhooks(variables)
            },
            sortTable (slug, direction) {
              this.sortValue = direction ? "ASC" : "DESC"
              this.loadWebhooks()
            },
            handleSelectResponseType(value) {
                this.responseType = value
                this.loadWebhooks()
            },
            handleSelectResponseCode(value) {
                this.responseCode = value;
                this.loadWebhooks()
            },
            handleSelectStatus(value) {
                this.statusValue = value;
                this.loadWebhooks()
            },
            handleSelectDates(value) {
                this.dates = value;
                this.loadWebhooks()
            },
            catchPagination(page) {
                this.page = page;
                this.loadWebhooks()
            },
            refresh () {
                this.searchString = ''
                this.statusValue = null
                this.responseCode = null;
                this.responseType = null;

                this.clearDates()

                this.loadWebhooks()
            },
            resetFilters() {
                this.refresh()
            }
        }
    }
</script>

<style scoped lang="scss">
.webhooks {
  padding: 0 30px 30px 30px;
  font-family: $Gilroy;
  &__mobileList {
    display: none;
  }
    &__topBar {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-bottom: 30px;
    }
  &__gridRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
      align-items: baseline;
  }
  &__searchRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .search {
      width: 93%;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: $h1;
    color: var(--new-front-text-primary-bg-modal-primary);
    margin-left: $p5;
    margin-bottom: $p5;
    line-height: 31px;
    > svg {
      margin-left: 5px;
    }
  }
  &__subtitle {
    font-size: $h2;
    font-weight: 400;
    color: var(--new-front-secondary-font-color);
    margin-bottom: $p30;
    margin-left: $p5;
  }
  &__table {
    &-empty {
      font-weight: 500;
      padding: 30px;
      font-size: 24px;
      text-align: center;
      margin-top: 20px;
      color: var(--new-front-text-primary-bg-modal-primary);
    }
  }
}
.webhooks__topBar--mobile {
  display: none;
}
::v-deep {
  .copy-wrapper {
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .webhooks__table {
    display: none;
    &-empty {
      font-size: 16px;
      border-top: 1px solid var(--new-front-divider-color)
    }
  }
  .webhooks__mobileList {
    display: unset;
  }
}
@media (max-width: 576px) {
  .webhooks {
    padding: 0 $p10 $p10 $p10;
  }
  .webhooks__topBar {
    display: none;
    &-filterMobile {
      min-width: 280px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
  }
  .webhooks__topBar--mobile {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: $p20 $p20 0 $p20 ;
  }
  .webhooks__title {
    font-size: $h3;
    margin-top: $p10;
    font-weight: 500;
  }
  .webhooks__subtitle {
    font-size: $h6;
    margin-bottom: $p10;
  }
  ::v-deep .container-new {
    padding-bottom: 30px;
  }

  ::v-deep .mx-input-wrapper {
    .mx-input {
      font-size: $h6;
      padding: 0 10px;
    }
  }
}
::v-deep {
  .advanced-balances-select-wrapper .sublabel {
    display: none;
  }
  .advanced-balances-select-wrapper .label {
    display: none;
  }
}
::v-deep .icon  {
  .currency-network-icon__network {
    width: 22px !important;
    height: 22px !important;

    border: none !important;
    position: unset !important;

    top: unset !important;
    left: unset !important;

    transform: unset !important;
  }
}
::v-deep .advanced-balances-select-wrapper .input-wrapper .input {
  background: var(--new-front-main-bg);
}
::v-deep {
    .webhooks__searchRow .search {
        width: 97%;
    }
}
::v-deep .advanced_balances__network-select {
    margin: 0 !important;
}
</style>
