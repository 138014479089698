import gql from 'graphql-tag';

export default gql`
query ($organizationId: String!, $intervalInDays: Int!) {
  collectDashboard(organizationId: $organizationId, intervalInDays: $intervalInDays) {
    stats {
      orders {
        countPaid
        countUnPaid
        countPaidLastInterval
        countUnPaidLastInterval
        
        amountUSD
        amountUSDLastInterval
        
        amountEUR
        amountEURLastInterval
        
        currencies {
          id
          amount
          amountLastPeriod
          countPaid
          countUnPaid
          countPaidLastInterval
          countUnPaidLastInterval
          amountUSD
          amountUSDLastInterval
          amountEUR
          amountEURLastInterval
        }
        networks {
          id
          amount
          amountLastPeriod
          countPaid
          countUnPaid
          countPaidLastInterval
          countUnPaidLastInterval
          amountUSD
          amountUSDLastInterval
          amountEUR
          amountEURLastInterval
        }
      }
      
      personal {
        count
        countLastInterval
        
        amountUSD
        amountUSDLastInterval
        
        amountEUR
        amountEURLastInterval
        
        currencies {
          id
          amount
          amountLastPeriod
          count
          countLastInterval
          amountUSD
          amountUSDLastInterval
          amountEUR
          amountEURLastInterval
        }
        networks {
          id
          amount
          amountLastPeriod
          count
          countLastInterval
          amountUSD
          amountUSDLastInterval
          amountEUR
          amountEURLastInterval
        }
      }
    }
    
    availableBalances {
      amountUSD
      amountUSDLastInterval
      amountEUR
      amountEURLastInterval
      currencies {
        id
        amount
        amountUSD
        amountEUR
      }
      networks {
        id
        amount
        amountUSD
        amountEUR
      }
      addresses {
        id
        address
        tag
        currencyId
        networkId
        amount
        amountLastInterval
        amountUSD
        amountUSDLastInterval
        amountEUR
        amountEURLastInterval
        riskLevel
        alias
        comment
      }
    }
    amountToCollect {
      availableToCollect {
        amountUSD
        amountEUR
        currencies {
          id
          amount
          amountUSD
          amountEUR
        }
        networks {
          id
          amount
          amountUSD
          amountEUR
        }
      }
      dustActives {
        amountUSD
        amountEUR
        currencies {
          id
          amount
          amountUSD
          amountEUR
        }
        networks {
          id
          amount
          amountUSD
          amountEUR
        }
      }
      highRisk {
        amountUSD
        amountEUR
        currencies {
          id
          amount
          amountUSD
          amountEUR
        }
        networks {
          id
          amount
          amountUSD
          amountEUR
        }
      }
    }
  }
}
`
