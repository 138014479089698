<template>
    <div class="loader-wrapper" v-if="loading">
        <new-spinner />
    </div>

    <div v-else-if="viewUnauthorizedRouter">
        <RouterView/>
    </div>

  <div v-else :class="['page-wrapper', routeName === 'dashboard' ? '' : 'page-wrapper_span']">
    <div class="page-content">
      <main class="main">
        <div :class="['wrapper', routeName === 'dashboard' ? '' : 'wrapper_span'] ">
          <div
              :class="['grid-container', routeName === 'dashboard' ? '' : 'grid-container_span', isCreateDonationRoute ? 'grid-container_fullwidth' : '']">
            <div :class="[!isNavOpen ? 'nav-hidden' : '', 'nav']" v-if="!isCreateDonationRoute">
              <NNav />
            </div>
            <div :class="['content', routeName === 'dashboard' ? '' : 'content_span']">
              <VHeader />
              <tfa-banner v-if="!userTwoFactorAuth && !isCreateDonationRoute && !isAdvanceBalanceRoute" style="margin-bottom: 20px"/>
              <RouterView v-if="isInit" :key="$route.fullPath"/>
              <preloader v-else/>
            </div>
            <div class="nav-right" v-if="routeName === 'dashboard'">
              <RouterView name="DashboardOrders" :key="$route.fullPath"/>
            </div>

          </div>
        </div>
      </main>
    </div>
    <VFooter/>
    <QRCodeModal/>
    <KYCModal />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import VHeader from "/public/components/general/header/VHeader.vue";

import VFooter from "/public/components/general/footer/VFooter.vue";
import TfaBanner from "./components/common/tfa-banner.vue";
import QRCodeModal from "./components/modals/twoFactorAuthorization/QRCodeModal.vue";
import Preloader from "./components/common/preloader.vue";
import axios from "axios";
import store from "./store";
import KYCModal from "./components/verification/KYCModal";
import NNav from "./components/general/NNav";
import {rebuild_client} from "./graphQl/apiClient";

export default {
    name: 'App',
    components: {
        NNav,
        KYCModal,
        Preloader,
        TfaBanner,
        VHeader,
        VFooter,
        QRCodeModal,
        NewSpinner: () => import (/* webpackChunkName: "Spinner" */ "/public/components/common/uiKit/spinner/index.vue")
    },
    data() {
        return {
          isMenuOpen: false,
          loading: true,
          initialized: false,
        };
    },
  // async created() {
  //   if (!this.isInit)  {
  //     await this.init()
  //   }
  // },
    beforeMount() {
        // rebuild_client();

    window.$$checkRoute = this.checkRoute;

        this.preload();

        this.windowReloader();
    },
    mounted() {
        // Подключение интеркома
        // const isSupportActive = import.meta.env.VITE_IS_SUPPORT_ACTIVE

        // if (isSupportActive === "1") {
        //     this.addIntercom();
        // }
    },
    computed: {
        ...mapState('app', [
            'isInit',
            'isLoading',
            'isNavOpen',
            'modal'
        ]),
      ...mapState('app', {
        authorized: state => state.authorization.login,
        twoFaChallenge: state => state.authorization.twoFa,
      }),
        ...mapState('organizations', ['organizationKycStatus']),
        ...mapState('user', {
            userId: state => state.user.id,
            userOtpEnabled: state => state.user.otpToken,
        }),
      ...mapGetters('app', ['isOrganizationOwner']),
        userTwoFactorAuth() {
            return !!this.userOtpEnabled;
        },
        isCreateDonationRoute() {
            return this.$route && (this.$route.name === 'create-donation' || this.$route.name === 'edit-donation');
        },
      isAdvanceBalanceRoute() {
        return this.$route && (this.$route.name === 'advanced-balances');
      },
        routeName() {
            return this.$route && this.$route.name || '';
        },
        viewUnauthorizedRouter() {
            return !(this.$store.state.app.authorization.login && this.$store.state.app.authorization.twoFa);
        }
    },
    watch:{
        '$route.name': {
          immediate: true,
          deep: true,
            handler() {
              this.$nextTick(() => {
                  this.closeModal()
                  if (!this.organizationKycStatus && this.isOrganizationOwner) {
                      this.openModal({
                          name: 'KYCModal',
                      });
                  }
              })
            }
        }
    },
    methods: {
        ...mapActions('app', [
            'init',
            'openModal',
          'closeModal',
          'loginSubscriptions',
        ]),

        async preload() {
            try {
                const {data, headers} = await axios.get('/public-api/status');

                if(!data.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this.preload();
                }

                if(headers['set-cookie']) {
                  window.location.href = window.location.href + '';

                  return;
                }

                const {
                    authorization,
                    lang,
                    preCheckout,
                } = data.result;

                store.commit('app/setAuthLogin', authorization.authorized);
                store.commit('app/setAuthTwoFa', authorization.twoFaChallenge);
                store.commit('donations/setBaseUrl', preCheckout.baseUrl);

              rebuild_client();

                await this.waitCookie();

                await this.checkRoute();


                // if(window.$$gqlPromise) {
                //   window.$$gqlPromise();
                // } else {
                //   window.$$gqlPromise = () => {};
                // }

                this.loginSubscriptions(this.onLoginUpdates);

                this.loading = false;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this.preload();
            }
        },

      async checkRoute() {
        if(!this.authorized) {
          switch (this.$route.name) {
            case 'login':
            case 'sign-up':
            case 'registration-confirm':
            case 'registration':
            case 'sign-up-confirm':
            case 'restore-password':{
              //
            } break;
              case 'closed-session':
                  //
              return;
            case 'login-2fa':
            default:{
              this.$router.push({name: 'login'});
            } break;
          }
        } else {
          if(!this.twoFaChallenge) {
            this.$router.push({name: 'login-2fa'});
            switch (this.$route.name) {
                  case 'login-2fa':{
                      //
                  }break;
                  default:{
                      this.$router.push({name: 'login-2fa'});
                  }break;
            }
          } else {
            switch (this.$route.name) {
              case 'login':
              case 'login-2fa':
              case 'sign-up':
              case 'registration':
              case 'registration-confirm':
              case 'sign-up-confirm':
              case 'restore-password': {

                this.$router.push({name: 'dashboard'});
                await this.__init();
              }break;
                case 'closed-session':
                    //
                return;
              default:{
                await this.__init();
              }break;
            }
          }
        }
      },
        async __init() {
          if(this.initialized) {
            return;
          }

          this.initialized = true;

            try {
                await this.init();
            } catch (e) {
              this.initialized = false;
                await new Promise(r => setTimeout(r, 1000));

                this.preload();
            }
        },

        handleToggleMenu(isMenuOpen) {
            this.isMenuOpen = isMenuOpen;
        },
        addIntercom() {
            window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "scb13xgp",
                custom_launcher_selector:'#intercom_custom_activator',
            };

            (function(){
                let w = window;
                let ic = w.Intercom;

                if (typeof ic==="function") {
                    ic('reattach_activator');
                    ic('update',w.intercomSettings);
                }
                else {
                    let d = document;
                    let i = function() {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function(args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    let l = function() {
                        let s = d.createElement('script');
                        s.type='text/javascript';
                        s.async=true;
                        s.src='https://widget.intercom.io/widget/scb13xgp';
                        let x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s,x);
                    };
                    if(document.readyState==='complete'){l();}
                    else if (w.attachEvent) {
                        w.attachEvent('onload',l);
                    } else {
                        w.addEventListener('load',l,false);
                    }
                }
            })();
        },

      async onLoginUpdates(type) {
            if(type.indexOf('close') !== -1) {
                const closeSessionType = type.substring(5);

                /**
                 * USER = 0,
                 * PARALLEL_LOGIN = 1,
                 * STOLEN_KEY = 2,
                 * SYSTEM = 3,
                 */
                switch (closeSessionType) {
                    case '0':
                        // пользователь сам разлогинился или сессия истекла
                        break;
                    case '1':
                        // кто-то вошел под этим аккаунтом не разлогинившись здесь
                        this.$router.push({name: 'closed-session'});
                    return;
                    case '2':
                        // кто-то зашел с таким же токенов, но под другим ip или девайсом
                        window.location.href = '/'; // обязательно нужна перезагрузка

                        return;
                  case '3':
                    // сессия была раскинула из админки
                    break;
                  case '4':
                    const chunks = window.location.host.split('.').reverse();

                    if(chunks.length < 2) {
                      window.location.href = '/';

                      return;
                    }

                    window.location.href = 'https://' + chunks[1] + '.' +chunks[0] + '/';

                    return;
                }
            }

          await this.checkRoute();
      },

      windowReloader() {
            const events = [
                'scroll',
                'keypress',
                'click',
                'mouseenter',
                'mouseleave',
                'mousemove',
                'mouseover',
                'touchmove',
                'touchstart',
                'focus',
                'blur',
            ];

            for(const event of events) {
                window.addEventListener(event, this.onAction);
                document.addEventListener(event, this.onAction);
            }

          window._firstActivity = setTimeout(() => {
              window._shouldReload = true;
          }, 30 * 1000);
      },
        onAction(e) {
            /**
             * Логика такая
             *
             * при инициализации страницы, если пользователь н-времени ничего делает
             * то считаем, что страница просто вышла из сна по инициативе браузера/ос
             * в таком случае при появлении активности на странице надо перезагрузиться
             * а если сразу после пробуждения есть активность, то ничего не будем делать
             * просто убьем таймаут что он ничего не выставлял для перезагрузки
             *
             * так же при каждом действии пользователя на странице мы создаем таймер на
             * н-времени чтоб перезагрузить страницу, в таком случае, если пользователь
             * не дедает на странице ничего в течении этого времени, то перезагрузим страницу
             * и навешает обработчики как в первом абзаце
             * */

            if(window._firstActivity) {
                clearInterval(window._firstActivity);

                if(window._shouldReload) {
                    window.location.href = window.location.href + '';

                    return;
                }
            }

            if(window._lastActivity) {
                clearInterval(window._lastActivity);
            }

            window._lastActivity = setInterval(() => {
                window.location.href = window.location.href + '';
            }, 5 * 60 * 1000);
        },

      async waitCookie(attempt = 0) {
          if(document.cookie.indexOf('application.sid=') !== -1) {
            return;
          }

          if(attempt > 5) {
            window.location.href = window.location.href + '';
          }

          await new Promise(r => setTimeout(r, 1000));

          return this.waitCookie(attempt + 1);
      },
    },
};

</script>
//
<style lang="css">
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
</style>
<style lang="scss" scoped>
.loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--new-front-gradient-bg);
}
.main {
  height: 100vh;

  & .wrapper {
    display: grid;
    place-items: center;
    height: 100%;
    max-width: 100%;
  }

  & .grid-container {
    height: 100%;
    display: grid;
    overflow: auto;
    grid-template-columns: minmax(310px, 370px) minmax(min-content, 1180px) minmax(310px, 370px);
    width: 100%;
    justify-content: center;
    background: var(--new-front-main-grid-wrapper-bg);

    &_span {
      grid-template-columns: minmax(310px, 370px) minmax(min-content, 1550px);
    }

    &_fullwidth {
      grid-template-columns: 1fr;
    }
  }

  & .nav {
    position: sticky;
    top: 0;
    width: 100%;
    //padding-left: 30px;
    //padding-right: 30px;
    padding-right: $p5;

    height: fit-content;
    background: var(--new-front-main-grid-wrapper-bg);
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 6;
    @media (max-width: 1180px) {
      overflow-y: auto;
      z-index: 6;
    }
  }

  & .nav-right {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 35px;
    padding-top: 57px;

    &_span {
      background: var(--primary-color);
    }
  }

  & .header {
    //@media (max-width: 1180px) {
    //  display: none;
    //}
  }

  & .content {
    height: auto;
    background: var(--new-front-gradient-bg);
    display: flex;
    flex-direction: column;
    position: relative;
  }

  & .wrapper_span {
    margin: auto;
    width: 100%;
  }
}


@media screen and (max-width: 1335px) {
  .main {
    & .grid-container {

      grid-template-columns: minmax(310px, 370px) 1fr;

      &_fullwidth {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
      }
    }

    .nav-right {
      display: none;
    }
  }

}

@media screen and (max-width: 1180px) {
  .header_content {
    display: none;
  }
  .main {
    & .grid-container {
      overflow: auto;
      grid-template-rows: min-content;
      grid-template-columns:1fr;

      &_fullwidth {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
      }

    }

    //& .nav {
    //  position: sticky;
    //  top: 0;
    //  z-index: 3;
    //  box-shadow: 0px 8px 50px rgba(10, 17, 105, 0.08);
    //  border-radius: 0px 0px 4px 4px;
    //  padding-left: var(--padding-left-container);
    //  padding-right: var(--padding-right-container);
    //}

    //& .nav__toggled {
    //  height: 90px;
    //  overflow: hidden;
    //
    //}

    .nav-right {
      display: none;
    }
  }

}

@media screen and (max-width: 1180px) {
  .nav {
    z-index: 5;
    height: 100lvh !important;
  }
  .nav-hidden {
    display: none !important;
  }
  .nav-header {
    display: flex;
  }
  .nav-logo {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .main {
    & .content {
      height: 100vh;
    }
  }
}
@media screen and (max-width: 375px) {
  .main {
    .nav {
      padding-left: 10px;
      padding-right: 10px;
      height: 100vh;
    }
  }
}
</style>
