import gql from 'graphql-tag';

export default gql`
    query ($organizationId: String!){
        getAddressSummary(organizationId: $organizationId) {
            PAY_IN
            BUSINESS
            PAY_OUT
            RECURRENT
            PERSONAL
            TOTAL
        }
    }
`;