import updateOrganizationMutation from "/public/graphQl/mutations/updateOrganization.js";

export const updateOrganization = async ({commit, dispatch, rootGetters}, payload) => {
    const selectedOrganizationId = rootGetters['app/getSelectedOrganizationId'];
    let currenciesData;

    try {
        commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: updateOrganizationMutation,
            variables: {
                organizationId: selectedOrganizationId,
                name: payload.name,
                logoUrl: payload.logoUrl
            },
        }, {root: true});
    } catch (error) {
        throw new Error(error.message);
    } finally {
        commit('app/toggleLoading', null, {root: true});
        dispatch('getOrganization');
    }

    if (currenciesData.code === 0) {
        throw currenciesData.message;
    }

    return true;
};