import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!, $invoiceId: String!) {
        invoice(organizationId: $organizationId, invoiceId: $invoiceId) {
            id
            externalId
            accountId
            apiKeyId 
            invoiceLink
            orderId
            orderLink
            status 
            itemId
            itemDescription
            currencyId
            currencyName
            amount
            amountUSD
            receivedCurrency
            receivedCurrencyName
            receivedAmount
            receivedAmountUSD
            includeFee
            insurancePercent
            slippagePercent
            webhookURL
            returnURL
            applyAt
            processedAt
            expiresAt
            createdAt
            receivedNetworkId
            rate
            orderAmount
            orderExpiresAt
            
            currencies {
                id
                currency
                orderIndex
                allowDeposit
                networks {
                    id
                    name
                    alias
                    underMaintenance
                    isDefault
                    allowDeposit
                }
            }
        }
    }
`;
