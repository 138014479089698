<script>
    import Copy from "@/public/components/common/copy.vue";
    import Preloader from "/public/components/common/uiKit/spinner/index.vue";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import CurrencyIcon from "@/public/components/common/uiKit/CurrencyIcon.vue";
    import BigNumber from "bignumber.js";
    import {
      getNetworkFullNameByNetworkName,
      getNetworkShortNameByNetworkName,
      getNetworkSystemName
    } from "@/public/filters";
    import NetworkIcon from "@/public/components/common/uiKit/NetworkIcon.vue";
    import BaseButton from "@/public/components/common/uiKit/button/base/index.vue";
    import AddressRisks from "@/public/components/elements/address-risks/AddressRisks.vue";
    import SvgDustClear from "@/public/components/elements/dashboard/assets/SvgDustClear.vue";
    import DashboardInfoItem from "@/public/components/elements/dashboard/DashboardInfoItem.vue";
    import {cryptoColors} from "@/public/components/elements/dashboard/constants";
    export default {
        name: "DashboardInfo",
        props: {
        },
        components: {
          DashboardInfoItem,
          SvgDustClear,
          AddressRisks,
          BaseButton,
          NetworkIcon,
          CurrencyIcon,
          Copy,
          Preloader
        },
      computed: {
        cryptoColors() {
          return cryptoColors
        },
        ...mapState('dashboard', ['infoPayload']),
        ...mapGetters('dashboard', ['currentSymbol', 'isUsdShow']),
        ...mapGetters('organizations', ['userRoleRules']),
        ...mapState('user', {
          userOtpEnabled: state => state.user.otpToken,
        }),
        isWithdrawalDisabled () {
          return !this.userRoleRules['collecting'].entities.make_withdrawal
        },
        isSwapDisabled () {
          return !this.userRoleRules['wallets_crosschain_swap'].entities.make_crosschain_swap
        },
        item() {
          return this.infoPayload;
        },
        isCurrencyTab() {
          return !!this.item.currency
        },
        title () {
          return this.isCurrencyTab ? this.item.alias : getNetworkFullNameByNetworkName(this.item.name)
        },
        ticker () {
          return this.isCurrencyTab ? this.item.currency : `network ${getNetworkShortNameByNetworkName(this.item.name)}`
        },
        // amount () {
        //   return this.isCurrencyTab ? this.item.amount : this.item.amount
        // },

        currencyAmount () {
          return this.isUsdShow ? this.item.amountUSD : this.item.amountEUR
        },
        limitItems () {
          return this.item.items.slice(0, 3)
        },
        dustAmount () {
          if (!this.item.dust) return 0
          return this.isUsdShow ? this.item.dust.amountUSD : this.item.dust.amountEUR
        },
        bgImage() {
          if (this.isCurrencyTab) {
            return new URL(`./assets/infoIcons/currencies/${this.item.currency.toUpperCase()}.svg`, import.meta.url).href;
          }
          return new URL(`./assets/infoIcons/networks/${this.item.name.toUpperCase()}.svg`, import.meta.url).href;
        },
      },
        methods: {
            ...mapActions('app', ['openModal']),
            ...mapMutations('dashboard', ['setDashboardInfoState', 'setDashboardInfoPayload']),
          openActionModal (value, name) {
              this.closeInfo();
            if (!this.userOtpEnabled && name === 'DashboardCreateWithdrawalRequestModalPayout') {
              this.openModal({
                name: 'TwoFaRestriction',
              });
              return;
            }
            this.openModal({
              name,
              payload: {
                addressId: value.id,
                ...value
              }
            });
          },
            closeInfo () {
                this.setDashboardInfoState(false)
                this.setDashboardInfoPayload(null)
            },
        }
    }
</script>

<template>
<div class="info">
    <div class="info__bg" @click="closeInfo"></div>
    <div  class="info__content">
        <transition name="slide-fade" mode="out-in">
            <div v-if="true">
              <div class="topBar">
                <div class="info__close" @click="closeInfo">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.712087 0.712087C1.0782 0.345971 1.6718 0.345971 2.03791 0.712087L7 5.67418L11.9621 0.712087C12.3282 0.345971 12.9218 0.345971 13.2879 0.712087C13.654 1.0782 13.654 1.6718 13.2879 2.03791L8.32582 7L13.2879 11.9621C13.654 12.3282 13.654 12.9218 13.2879 13.2879C12.9218 13.654 12.3282 13.654 11.9621 13.2879L7 8.32582L2.03791 13.2879C1.6718 13.654 1.0782 13.654 0.712087 13.2879C0.345971 12.9218 0.345971 12.3282 0.712087 11.9621L5.67418 7L0.712087 2.03791C0.345971 1.6718 0.345971 1.0782 0.712087 0.712087Z" fill="var(--new-front-primary-font-color)"/>
                  </svg>
                </div>

                <div class="topBar__info">
                  <div :class="['topBar__info-title']" :style="{ color: isCurrencyTab ? cryptoColors[item.currency] : cryptoColors[item.name.toUpperCase()] }">{{ title }}</div>
                  <div class="topBar__info-ticker">{{ ticker }}</div>
                  <div v-if="!isCurrencyTab" class="topBar__info-ticker">{{ item.items[0].address | preparedString }} <copy :value="item.items[0].address" /></div>
                </div>
                <div class="topBar__sum">
                  <template v-if="!isCurrencyTab">
                    {{ currentSymbol }}{{ currencyAmount }}
                  </template>
                  <template v-if="isCurrencyTab">
                    {{ item.amount }} {{ ticker }}
                  </template>
                </div>
                <div v-if="isCurrencyTab" class="topBar__info-ticker" :style="{ marginTop: '5px' }">
                  {{ currentSymbol }}{{ currencyAmount }}
                </div>
                <div class="topBar__dust">
                  <SvgDustClear />
                  <div class="topBar__dust-title">{{ $t('payments.dust') }}:</div>
                  <div class="topBar__dust-value">
                    {{ currentSymbol }}{{ dustAmount }}
<!--                    <span class="orange-text">(0,32%)</span>-->
                  </div>
                </div>
                <address-risks class="info__risk" :status="item.riskLevel" />
                <div class="topBar__bg" :style="{ background: `linear-gradient(270deg, ${isCurrencyTab ? cryptoColors[item.currency] : cryptoColors[item.name.toUpperCase()]} -10%, var(--bg-background-secondary) 60%, var(--bg-background-secondary) 100%)` }">
                  <img :src="bgImage" alt="infoBackground">
                </div>
              </div>
              <div class="info__list">
                <DashboardInfoItem v-for="balance in item.items" :key="balance.id" :balance="balance" :main-currency="item.currency" :is-currency-tab="isCurrencyTab" :isWithdrawalDisabled="isWithdrawalDisabled" :isSwapDisabled="isSwapDisabled" @openActionModal="openActionModal" @openModalSwap="openActionModal" @closeInfo="closeInfo" />
              </div>
            </div>
            <preloader v-else />
        </transition>
    </div>
</div>
</template>

<style scoped lang="scss">
.topBar {
  position: relative;
  padding:  30px 0 40px 50px;
  border-bottom: 1px dashed var(--border-border-input-primary-default);
  overflow: hidden;
  &__info {
    margin-top: 15px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
    &-title {
      color: #627EEA;

      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 5px;
    }
    &-ticker {
      color: var(--text-color-text-secondary, #8B81A0);
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      z-index:2;
      position: relative;
    }
  }
  &__sum {
    position: relative;
    z-index: 2;
    color: var(--text-text-primary, #000);
    font-size: 32px;
    font-weight: 600;
    line-height: 100%;
  }
  &__dust {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    gap: 5px;
    color: var(--text-color-text-tertiary, #928F98);
    margin-top: 30px;
    &-value {
      display: flex;
      align-items: center;
      color: var(--text-text-primary);
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    img {
      height: 165%;
      /* width: 75%; */
      position: absolute;
      right: -167px;
      bottom: -100px;
    }
  }
}
.info {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // Огромный z-index для перекрытия виджета интеркома
  z-index: 2147483001;
  font-family: $Gilroy;

    &__close {
        cursor: pointer;
      position: relative;
      z-index: 2;
    }
    &__risk {
      position: relative;
      z-index: 2;
    }
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, .50);
        height: 100vh;
        width: 100vw;
        z-index: 1;
        cursor: pointer;
    }
    &__content {
    max-width: 530px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background: var(--bg-background-secondary);
    position: absolute;
    right: 0;
    z-index: 2;
    overflow: auto;
  }
}
.slide-fade-enter-active {
    transition: all .3s ease;
}
.slide-fade-leave-active {
    transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
::v-deep .copy-wrapper {
    margin: 0;
}
@media screen and (max-width: 576px) {
  .topBar {
    padding: 20px 0 50px 20px;
  }
    .info__header-title {
        font-size: $h2;
    }
    .info__item-title {
        font-size: $h5;
    }
    .info__item-value {
        font-size: $h5;
    }
    .info__header-subtitle {
        font-size: $h5;
    }
    .info__content {
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        overflow: auto;
    }
}

.orange-text {
  color: var(--ocp-status-medium-medium-orange-primary);
}
</style>
