export const getters = {
    getDifference: (state) => (curr, prev) => {
        const numberCurr = Number(curr);
        const numberPrev = Number(prev);
        const percent = numberPrev ? (numberCurr / numberPrev * 100 - 100).toFixed(0) : 0;
        const value = (numberCurr - numberPrev).toFixed(2).replace(/\.00$/, "");

        return {
            percent,
            value
        }
    },
    getPercentOf: (state) => (partial, total) => {
        const numTotal = Number(total)
        const numPartial = Number(partial)

        return numPartial && numTotal ? ((100 * numPartial) /numTotal).toFixed(2).replace(/\.00$/, "") : 0;
    },
    isUsdShow: (state) => state.currentWallet === 'usd',
    currentSymbol: (state, { isUsdShow }) => isUsdShow ? '$' : '€',
    ordersInfo: (state) => state.dashboardRawData.stats.orders,
    personalInfo: (state) => state.dashboardRawData.stats.personal,
    availableBalances: (state) => state.dashboardRawData.availableBalances,
    availableBalancesAddressesInfo: (state, payload, rootState) => {
        const allCurrencies = rootState.app.currencies;
        return state.dashboardRawData.availableBalances.addresses.map(item => ({
            ...item,
            networkInfo: allCurrencies.find(({id}) => id === item.currencyId).networks.find(({id}) => id === item. networkId),
        }))
    },
    amountToCollect: (state) => state.dashboardRawData.amountToCollect,
    totalOrdersCount: (state, getters) => getters.ordersInfo.countPaid + getters.ordersInfo.countUnPaid,
    totalPersonalCount: (state, getters) => getters.personalInfo.count,
    totalPersonalCountPrev: (state, getters) => getters.personalInfo.countLastInterval,
    totalOrdersCountPrev: (state, getters) => getters.ordersInfo.countPaidLastInterval + getters.ordersInfo.countUnPaidLastInterval,
    availableToCollect: (state, { amountToCollect, isUsdShow }) => {
        if (isUsdShow) {
            return amountToCollect.availableToCollect.amountUSD
        } else {
            return amountToCollect.availableToCollect.amountEUR
        }
    },
    availableForWithdrawal: (state, { availableBalances, isUsdShow }) => {
        if (isUsdShow) {
            return availableBalances.amountUSD
        } else {
            return availableBalances.amountEUR
        }
    },
    totalBalance: (state, { availableBalances, amountToCollect,  isUsdShow, dustAmount, dirtyAmount }) => {
        if (isUsdShow) {
            return (Number(availableBalances.amountUSD) +  Number(amountToCollect.availableToCollect.amountUSD) + Number(dustAmount) + Number(dirtyAmount)).toFixed(2)
        } else {
            return (Number(availableBalances.amountEUR) +  Number(amountToCollect.availableToCollect.amountEUR) + Number(dustAmount) + Number(dirtyAmount)).toFixed(2)
        }
    },
    depositsSum: (state, { personalInfo,ordersInfo, isUsdShow }) => {
        if (isUsdShow) {
            return (Number(personalInfo.amountUSD) + Number(ordersInfo.amountUSD)).toFixed(2)
        } else {
            return (Number(personalInfo.amountEUR) + Number(ordersInfo.amountEUR)).toFixed(2)
        }
    },
    depositsSumPrev: (state, { personalInfo,ordersInfo, isUsdShow }) => {
        if (isUsdShow) {
            return (Number(personalInfo.amountUSDLastInterval) + Number(ordersInfo.amountUSDLastInterval)).toFixed(2)
        } else {
            return (Number(personalInfo.amountEURLastInterval) + Number(ordersInfo.amountEURLastInterval)).toFixed(2)
        }
    },
    availableForWithdrawalPrev: (state, { availableBalances, isUsdShow }) => {
        if (isUsdShow) {
            return availableBalances.amountUSDLastInterval
        } else {
            return availableBalances.amountEURLastInterval
        }
    },
    personalDepositsPrev: (state, { personalInfo, isUsdShow }) => {
        if (isUsdShow) {
            return personalInfo.amountUSDLastInterval
        } else {
            return personalInfo.amountEURLastInterval
        }
    },
    personalDeposits: (state, { personalInfo, isUsdShow }) => {
        if (isUsdShow) {
            return personalInfo.amountUSD
        } else {
            return personalInfo.amountEUR
        }
    },
    paymentsByOrders: (state, { ordersInfo, isUsdShow }) => {
        if (isUsdShow) {
            return ordersInfo.amountUSD
        } else {
            return ordersInfo.amountEUR
        }
    },
    paymentsByOrdersPrev: (state, { ordersInfo, isUsdShow }) => {
        if (isUsdShow) {
            return ordersInfo.amountUSDLastInterval
        } else {
            return ordersInfo.amountEURLastInterval
        }
    },
    dustAmount: (state, { amountToCollect, isUsdShow }) => {
        if (isUsdShow) {
            return amountToCollect.dustActives.amountUSD
        } else {
            return amountToCollect.dustActives.amountEUR
        }
    },
    dirtyAmount: (state, { amountToCollect, isUsdShow }) => {
        if (isUsdShow) {
            return amountToCollect.highRisk.amountUSD
        } else {
            return amountToCollect.highRisk.amountEUR
        }
    },



    dashboardCurrencies: (state, { availableBalances, amountToCollect }, rootState) => {
        const riskLevelValues = {
            null: 0,
            white: 1,
            green: 2,
            yellow: 3,
            red: 4,
            black: 5,
        }

        const allCurrencies = rootState.app.currencies;

        const currencies = availableBalances.currencies.map((currency) => {
            const currencyInfo = allCurrencies.find(item => item.id === currency.id)

            const addresses = availableBalances.addresses.filter(item => item.currencyId === currency.id)

            const RiskValuesArr = [];

            addresses.forEach((item) => RiskValuesArr.push(riskLevelValues[item.riskLevel]));

            const maxRiskValue = Object.keys(riskLevelValues).find(key => riskLevelValues[key] === Math.max(...RiskValuesArr));

            const dust = amountToCollect.dustActives.currencies.find((item) => item.id === currency.id)

            const networks = currencyInfo.networks.map((network) => {
                const address = addresses.find(item => item.networkId === network.id)
                if (!address) return false
                return {
                    name: network.name,
                    ...address,
                }
            }).filter(item => item)


            return {
                ...currency,
                ...currencyInfo,
                items: networks,
                dust,
                riskLevel: maxRiskValue === "null" ? null : maxRiskValue,
            }
        });

        return currencies
    },
    dashboardNetworks: (state, { availableBalances, amountToCollect }, rootState) => {
        const allCurrencies = rootState.app.currencies;
        const allNetworks = rootState.app.networks;

        const riskLevelValues = {
            null: 0,
            white: 1,
            green: 2,
            yellow: 3,
            red: 4,
            black: 5,
        }

        const networks = availableBalances.networks.map((network) => {

            const networkInfo = allNetworks.find(item => item.id === network.id)

            const dust = amountToCollect.dustActives.networks.find((item) => item.id === network.id)

            const addresses = availableBalances.addresses.filter(item => item.networkId === network.id)

            const RiskValuesArr = [];

            addresses.forEach((item) => RiskValuesArr.push(riskLevelValues[item.riskLevel]));

            const maxRiskValue = Object.keys(riskLevelValues).find(key => riskLevelValues[key] === Math.max(...RiskValuesArr));


            const currencies = []

            allCurrencies.forEach((currency) => {
                if (currency.networks.some(item => item.id === network.id)) {
                    const address = addresses.find(item => item.currencyId === currency.id)
                    if (!address) return false
                    currencies.push({
                        currencyAlias: currency.alias,
                        currency: currency.currency,
                        ...address
                    })
                }
            })

            return {
                ...network,
                ...networkInfo,
                items: currencies.filter(item => item),
                dust,
                riskLevel: maxRiskValue === "null" ? null : maxRiskValue,
            }
        })

        return networks

    }
}
