<template>
<bh-select-new
        v-model="innerModel"
        :placeholder="$t('invoice.select-currencies')"
        class="grouping-select item"
        :list="list"
        :label="$t('invoice.available-currencies-list')"
        multiple
        clearable
        gap
>
    <template #info="item">
        <div class="currency-network-option-item">
            <div class="currency-and-network-info column">
                <currency-network-icon :currency="item.currency.currency" :network="item.network.name.toUpperCase()" />
                <span class="currency">
                {{ item.currency.alias }}
              </span>
                <span class="network">
                {{ item.network.name | getNetworkShortNameByNetworkName }}
              </span>
            </div>
        </div>
    </template>
    <template
            #selected="item"
    >
        <div class="currency-network-option-item">
                <div class="currency-and-network-info column">
                    <currency-network-icon :currency="item.currency.currency" :network="item.network.name.toUpperCase()" />
                    <span class="currency">
                {{ item.currency.alias }}
              </span>
                    <span class="network">
                {{ item.network.name | getNetworkShortNameByNetworkName }}
              </span>
                 <span
                            v-if="innerModel.length > 1"
                            class="selected-quantity"
                    >
                    +{{innerModel.length - 1}}
                </span>
                </div>
        </div>
    </template>
</bh-select-new>
</template>

<script>
    import BhSelectNew from '/public/components/elements/advance-balances/selects/Base'
    import {mapState} from "vuex";
    import NetworkAndCurrencyItem from "@/public/components/elements/orders/Select/Item.vue";
    import CurrencyImg from "@/public/components/elements/CurrencyImg.vue";
    import CurrencyNetworkIcon from "@/public/components/elements/business-walet/Select/CurrencyNetworkIcon.vue";

    export default {
        name: "InvoicesMultiCurrencySelect",
        components: {
            CurrencyNetworkIcon,
            CurrencyImg,
            NetworkAndCurrencyItem,
            BhSelectNew
        },
        props: {
            value:  {
                type: Array,
                default: () => ([])
            },
        },
        computed: {
            ...mapState('app', ['currencies']),
            innerModel: {
                get({ value, list }) {
                    return value.map( arr => list.find(item => item.currencyId === arr[0] && item.networkId === arr[1]))
                },
                set(v) {
                    this.$emit('input', v.map(({ networkId, currencyId }) => [currencyId, networkId]))
                }
            },
            list ({ currencies }) {
                return currencies.reduce(( acc, curr ) => {
                    if (curr.networks) {
                        const { allowInvoice } = curr

                        if (allowInvoice) {
                            curr.networks.forEach(network => {
                                if (!network.allowDeposit) {
                                    return
                                }
                                const currencyWithNetwork = {
                                    id: `${curr.id}_${network.id}`,
                                    networkId: network.id,
                                    currencyId: curr.id,
                                    currency: curr,
                                    network: network,
                                    label: `${curr.currency} (${network.name.toUpperCase()})`,
                                }

                                acc.push(currencyWithNetwork)
                            })
                        }
                    }

                    return acc
                }, [])
            },
        },

    }
</script>

<style lang="scss" scoped>
.selected-quantity {
  color: var(--new-front-primary-font-color);
  padding-left: 5px
}
::v-deep {
    .new-order-modal__row {
        width: 100%;
    }
  .placeholder {
    font-weight: 400;
  }
  .label {
    font-size: 13px;
  }
  @media screen and (max-width: 576px) {
    .placeholder {
      font-size: 13px;
    }
    .label {
      font-size: 10px;
    }
    .currency {
      font-size: 13px;
    }
    .network {
      font-size: 13px;
    }
    .currency-network-icon-wrapper .currency-network-icon__network {
      width: 14px;
      height: 14px;
    }
    .currency-network-icon-wrapper .currency-network-icon__currency {
      width: 18px;
      height: 18px;
    }
  }
}
.grouping-select {
  width: 100%;
  @media screen and (max-width: 576px) {
      margin-bottom: 20px;
  }

}
.currency-network-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-style: normal;

    min-height: 32px;
    margin-left: 10px;

    .currency-and-network-info {
        display: flex;
        align-items: center;

        color: #8F949A;

        gap: 7px;

        .currency {
            color: var(--new-front-text-primary-bg-secondary);
        }
        .network {
            color: var(--new-front-text-secondary-bg-secondary);
        }
    }
    .amount {
        display: flex;
        align-items: end;
        gap: 8px;

        .amount-label {
            font-weight: 400;
            color: var(--new-front-text-secondary-bg-secondary);
        }
        .amount-value {
            color: var(--new-front-text-primary-bg-secondary);
        }
    }

    @include below_880() {
        .column {
            width: 100%;
            &.amount {
                display: none;
            }
        }
    }

    @include below_phone(){
        .currency-left-block {
            .currency-info {
                width: 100%;
                padding: 0 15px;

                .name {}
            }

            .network-info {
                display: none;
                width: 60%;
            }
        }
      ::v-deep {
        .grouping-select {
          .label {
            font-size: 13px;
          }
        }
        .new-bh-select-wrapper .input-wrapper .input .placeholder {
          font-size: 13px;
        }
      }
    }
}
@include below_phone(){
  ::v-deep {
    .grouping-select {
      .label {
        font-size: 13px;
      }
    }
    .new-bh-select-wrapper .input-wrapper .input .placeholder {
      font-size: 13px;
    }
  }
}
.options-list-item {
    &:hover {
        .currency-network-option-item {
            .currency-info-left-block {
                .currency-info {
                    .alias {
                        color: var(--primary-font-color);
                    }
                }
                .network-info {
                    color: var(--primary-font-color);
                }
            }
        }
    }
}
</style>
