import getOrganizationRolesQuery from "/public/graphQl/queries/getOrganizationRoles.js";

export const getOrganizationRoles = async ({commit, dispatch, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    let currenciesData;

    try {
        commit('app/toggleLoading', null, {root: true});
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getOrganizationRolesQuery,
            variables: {
                organizationId
            }
        }, {root: true});
    } catch (error) {
        return error;
    } finally {
        commit('app/toggleLoading', null, {root: true});
    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {
            getOrganizationRoles,
        },
    } = currenciesData;

    commit('setListRoles', getOrganizationRoles);

    return true;
};
