import Vue from 'vue';
import moment from "moment";

export const getFormatDate = (date, format = 'DD.MM.YYYY') => {
    return moment(date).format(format);
};

export const getFormatDateTime = (date, format = 'DD.MM.YYYY HH:mm') => {
    return moment(date).format(format);
};

export const getTruncatedString = txt => {
    return (txt && txt.length > 8) ? `${txt.substr(0, 4)}****${txt.substr(-4)}` : txt
}

Vue.filter('getTruncatedString', getTruncatedString);
Vue.filter('getFormatDate', getFormatDate);