export const cryptoColors = {
    USDT: '#53AE94',
    BCH: '#0AC18E',
    BITCOINCASH: '#0AC18E',
    ETH: '#627EEA',
    ETHEREUM: '#627EEA',
    SEPOLIA: '#627EEA',
    LTC: '#345D9D',
    LITECOIN: '#345D9D',
    BUSD: '#F0B90B',
    USDC: '#2775CA',
    USDD: '#216C58',
    POLYGON: '#8247E5',
    MATIC: '#8247E5',
    SHIB: '#F00500',
    FANTOM: '#13B5EC',
    EURC: '#2775CA',
    PYUSD: '#0071F3',
    EURT: '#53AE94',
    TRX: '#FF0015',
    TRON: '#FF0015',
    TON: '#0098EA',
    BTC: '#F7931A',
    BITCOIN: '#F7931A',
    BSC: '#F3BA2B',
    BNB: '#F3BA2B',
    'BSC-TESTNET': '#F3BA2B',
}
