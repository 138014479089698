import gql from 'graphql-tag';

export default gql`
    mutation updateOrganization($organizationId: String!, $name: String!, $logoUrl: String) {
        updateOrganization(organizationId: $organizationId, name: $name, logoUrl: $logoUrl) {
            code
            result
        }
    }
`;
