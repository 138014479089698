export const state = {
    currentTabSelect: 0,
    listUsers: [],
    listRoles: [],
    detailRole: false,
    detailRoleId: null,
    role: null,
    selectedRole: null,
    changeRole: false,
    createRole: false,
    inviteUrl: '',
    organizationName: null,
    organizationImgLink: null,
    organizationImgFile: null,
    organizationKycStatus: true,
};
