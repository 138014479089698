import gql from 'graphql-tag';

export default gql`
    query($organizationId: String!) {
        getOrganization(organizationId: $organizationId) {
            name
            id
            kycApprove
            logoUrl
            isTest
            nextCollectingAt
        }
    }
`;
