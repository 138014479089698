import getOrganizationQuery from "/public/graphQl/queries/getOrganization.js";

export const getOrganization = async ({commit, dispatch, rootGetters}) => {
    const organizationId = rootGetters['app/getSelectedOrganization'].id;
    const isOwner = rootGetters['app/isOrganizationOwner'];

    if (!isOwner) {
        return;
    }

    let currenciesData;

    try {
        currenciesData = await dispatch('app/request', {
            type: 'query',
            gql: getOrganizationQuery,
            variables: {
                organizationId
            },
        }, {root: true});
    } catch (error) {
        return error;
    } finally {

    }

    if (currenciesData.message) {
        throw currenciesData.message;
    }

    if (currenciesData.errors) {
        throw currenciesData.errors;
    }

    const {
        data: {
            getOrganization: {
                id,
                name,
                logoUrl,
                kycApprove
            },
        },
    } = currenciesData;

    commit('setOrganizationName', name);
    commit('setOrganizationImgFile', null);
    commit('setOrganizationImgLink', logoUrl);
    commit('setOrganizationId', id);
    commit('setOrganizationKyc', kycApprove);

    return true;
};
